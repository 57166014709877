<template lang="pug">
managementPage.scrollable(title="Assets"
              @fetchData="loadList"
              @openEditor="openEditor"
              :searchPageSize="searchPageSize",
              :showTotals="true"
              :pagination="pagination"
              :creatable="canView('/settings/assets/new')")
  router-view(v-slot:editor)
  template(v-slot:body)
    aiq-table.manage-assets-scrollbar(:data="items" v-loading="loading")
      aiq-table-column(label-class-name="is-bordered", width="175")
        template(v-slot:default="scope")
          aiq-image.asset-preview(:key="scope.row.id" :isGif="isGif(scope.row)" :file="$_get(scope.row, 'file.payload.thumbnail')")
      aiq-table-column(label="Title", prop="title", :sort-method="onSortTitle", :sortable="true", label-class-name="is-bordered")
      aiq-table-column(label="Type", prop="file.payload.extension", :sortable="true", label-class-name="is-bordered")
      aiq-table-column(label="Size", label-class-name="is-bordered")
        template(v-slot:default="scope")
          span(v-if="scope.row.file && $_get(scope.row, 'file.payload.dimensions', '')") {{ $_get(scope.row, 'file.payload.dimensions', '') }} px
      aiq-table-column(label="Description", prop="description", label-class-name="is-bordered", min-width="194")
      aiq-table-column(label="Keywords", label-class-name="is-bordered", class-name="cell-keywords", min-width="164")
        template(v-slot:default="scope")
          aiq-keyword-list(:list="scope.row.keywords")
      aiq-table-column(align="center"
                      label="Link"
                      label-class-name="is-bordered"
                      width="63")
        template(v-slot:default="scope")
          a.aiq-link-preview(@click="$_openImage (scope.row)", target="_blank") View
      aiq-table-column(v-if="canView('/settings/assets/:number')" label-class-name="is-bordered", width="71")
        template(v-slot:default="scope")
          aiq-button(link type="primary" size="small", @click="openEditor(scope.row)") Edit
</template>

<script>
import { mapState } from 'vuex';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { PAGINATION_DEFAULT } from '@/constants/pagination';
import { extractErrorMessage } from '@/libs';
import ManagementPage from '../../../components/ManagementPage/ManagementPage.vue';
import GetGlobalSource from '@/libs/mixins/GetGlobalSource';

import { STATUS_CODES } from '@/constants';

const SEARCH_PAGE_SIZE = 50;

export default {
  name: 'Assets',
  mixins: [GetGlobalSource],
  components: {
    ManagementPage,
  },
  data() {
    return {
      pagination : PAGINATION_DEFAULT,
      loading : true,
      pageResource : '/settings/assets',
      assetEditForm : false,
      temporalAsset : {},
      searchPageSize : SEARCH_PAGE_SIZE,
    };
  },
  computed: {
    ...mapState({
      items: state => state.assets.assets,
      tags: state => state.tags.tags || [],
    }),
    keywords() {
      return this.tags;
    },
  },
  created() {
    this.$_get = (object, key, defaultValue) => get(object, key, defaultValue);

    this.$_openImage = async (row) => {
      const url = await this.getFileUrl(get(row, 'file.payload'));
      if (url) {
        window.open(url);
      }
    };
  },
  mounted() {
    this.$store.dispatch('tags/getTagsList');
  },
  methods: {
    isGif(row) {
      return get(row, 'file.payload.extension') === 'gif';
    },
    async loadList({ searchTerm, searchOffset }) {
      const params = {
        limit: this.searchPageSize,
        query: searchTerm,
        offset: searchOffset,
      };

      const response = await this.$store.dispatch('assets/getAssetsList', [
        params,
        searchOffset === 0,
      ]);

      this.loading = false;
      this.pagination = get(response, 'data.pagination', PAGINATION_DEFAULT);
      return response;
    },

    onSortTitle(a, b) {
      const aValue = a.title.toLowerCase().replace(/\s+/g, '');
      const bValue = b.title.toLowerCase().replace(/\s+/g, '');
      return aValue < bValue ? 1 : -1;
    },
    openEditor(record) {
      this.record = {
        ...this.$store.getters['assets/assetModel'],
        ...record,
      };

      this.$store.dispatch('assets/selectAsset', this.record)
        .then(() => {
          const recordId = this.record.id || 'new';
          const path = `${this.$route.path}/${recordId}`;
          this.$router.push({ path });
        });
    },
    closeAssetForm() {
      this.assetEditForm = false;
    },
    createOrUpdateAsset(asset) {
      const action = `assets/${asset.id ? 'updateAsset' : 'createAsset'}`;
      const payload = {
        ...asset,
        file_id: get(asset, 'file.file_id'),
      };
      this.$store.dispatch(action, [payload, 'data'])
        .then(() => {
          this.$aiq.notify.success(`Asset has been ${asset.id ? 'updated' : 'created'}.`);
          this.closeAssetForm();
        })
        .catch(err => {
          switch (err.status) {
            case STATUS_CODES.BAD_REQUEST:
              this.$aiq.notify.error(extractErrorMessage(err) || 'Validation failed.');
              break;
            default:
              this.$aiq.notify.error(err.data || 'Creation failed.');
              break;
          }
        });
    },

    deleteAsset(asset) {
      this.$store.dispatch('assets/deleteAsset', asset.id)
        .then(() => {
          this.$aiq.notify.success('Asset has been deleted.');
          this.closeAssetForm();
        })
        .catch(err => {
          this.$aiq.notify.error(extractErrorMessage(err) || 'Asset deletion failed.');
        });
    },
    addKeywordToAsset([asset, keyword]) {
      const keywords = uniq([...asset.keywords, keyword]);
      this.temporalAsset = { ...asset, keywords };
    },

    deleteKeywordFromAsset([asset, keyword]) {
      const keywords = asset.keywords.filter(k => k !== keyword);
      this.temporalAsset = { ...asset, keywords };

      if (!keywords.length) {
        return this.$aiq.notify.error('Asset must have at least 1 keyword.');
      }
    }

  }
};
</script>

<style lang="scss" scoped>
  @import "../../../styles/aiq-mixins.scss";

  .aiq-link-preview{
    cursor: pointer;
  }
</style>

<style lang="scss">
@import "../../../styles/aiq-mixins.scss";

.manage-assets-scrollbar {
  .el-table__body-wrapper {
    @include scrollable(calc(100vh - 210px));
  }
  td > .cell {
    word-break: unset;
    text-align: justify;
    padding: 5px;
  }
}
</style>
