<template lang="pug">
  management-page.content(title="Summary"
                :creatable="false"
                :searchable="false"
                :showDownloadButton="true"
                :showHeader="false")
    template(v-slot:body)
      .toolbar
        aiq-tooltip.help-icon(placement="left")
            template(v-slot:content)
              div(v-html="helpText")
            i.iq-ico-explanation
        .flex-spacer
          FilteredInput.search(v-model="searchTerm" size="small" placeholder="Search Selections")
        .dropdown-filters
          .dropdown-filter
            segment-select(:customer-segment-filter-options="CustomerSegmentFilterOptions"
                      :segment-alias="segmentAlias"
                      is-select-multiple
                      is-select-collapse-tags
                      @change="onFilterChange"
                      @on-segment-hover="onSegmentHover")
        .time-range-filter
          aiq-date-picker(v-model="dateRange"
                          @change="handleDatePickerChange($event)"
                          type="daterange"
                          placeholder="Select date range",
                          popper-class="metrics-time-range__date-picker-popup"
                          :shortcuts="datePickerOptions.shortcuts"
                          :disabled-date="datePickerOptions.disabledDate"
                          :teleported="false"
                          format="YYYY/MM/DD"
                          :clearable="false")
        .download-button
          aiq-button.export(@click="exportTable")
            i.iq-ico-export
      .insights-selections
        aiq-col(:span="24")
            .selection(v-for="selection in selections")
                aiq-row(:gutter="16" type="flex" align="middle")
                    aiq-col(:lg="4" :span="24")
                        overview-chart(v-if="selection"
                                  :keepChartOnly="true"
                                  :chartConfig="selectionsChartConfig"
                                  :preLoadedchartData="selectionsChartData(selection.data_points)"
                                  visualization="aiq-donut-chart")
                    aiq-col(:lg="20" :span="24")
                        aiq-table.scrollbar(v-if="selection"
                                            :data="selection.data_points"
                                            v-loading="loading")
                          aiq-table-column(:label="getQuestion(selection)" align="center" :min-width="10" label-class-name="question-th")
                            template(v-slot:header="scope")
                              .question(:innerHTML="$filters.sanitize(scope.column.label)")
                            aiq-table-column(label="Option", :min-width="7", label-class-name="is-bordered")
                              template(v-slot:default="scope")
                                span {{ getOption(scope.row) }} &nbsp;
                            aiq-table-column(label="Selection Frequency", :min-width="10", label-class-name="is-bordered")
                              template(v-slot:default="scope")
                                span {{ getOptionFrequency(scope.row) }} &nbsp;
                            aiq-table-column(label="Selection %", :min-width="7", label-class-name="is-bordered")
                              template(v-slot:default="scope")
                                span {{ getSelectionPercent(scope.row) }} &nbsp;
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import moment from 'moment';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import OverviewChart from '@/components/Chart.vue';
import SegmentSelect from '@/components/SegmentsSelect/SegmentsSelect.vue';
import { datePickerDefaultOptions, dstDateRangeUtc, breakdownOptions, selectedBreakdownOption, defaultDateRange, setToEndOfDay, getNonReactiveDateRange, lastAvailableMonthAndQuarterRange, createPickerDateRangeOption } from '../../configs/dateRange';
import { downloadAsFile, unicodeToASCII } from '@/libs';
import cloneDeep from 'lodash/cloneDeep';
import selectionsChartConfig from '../../configs/selectionsChart';
import { dropDownToSelected } from '../../helpers/filterHelper';

const TOTAL_LABEL = 'TOTAL';
const NO_SELECTION_LABEL = 'NO SELECTION MADE';

export default {
  pageResource: '/metrics/insights',
  components: {
    ManagementPage,
    OverviewChart,
    SegmentSelect,
  },
  data() {
    return {
      selections: [],
      helpText: `Review the frequency and percentages of customer, or member, selections.
      Analyze this data to determine which, if any, options are being under-used.
      `,
      selectionsChartConfig,
      breakdownOptions,
      searchTerm: '',
      selectedFilters: [],
      dateRange: defaultDateRange(),
      selectedBreakdownOption: selectedBreakdownOption(),
      loading: true,
      segmentAlias: '',
    };
  },
  computed: {
    ...mapGetters({
      CustomerSegmentFilterOptions: 'configs/segments',
    }),
    datePickerOptions() {
      return {
        ...datePickerDefaultOptions(),
        shortcuts: lastAvailableMonthAndQuarterRange.map(createPickerDateRangeOption),
      };
    },
    dateRangeUtc() {
      return dstDateRangeUtc(this.dateRange);
    },
  },
  watch: {
    dateRange: debounce(function onDateRangeChange(value) {
      const [nStart, nEnd] = value;
      if (nStart && nEnd && nStart === nEnd) {
        return;
      }
      const [start, end] = value;
      if (!(start && end)) {
        return;
      }
      this.loadSelections();
    }, 1),
    searchTerm() {
      this.loadSelections();
    },
  },
  mounted() {
    this.$store.dispatch('configs/getCustomerSegments');
    this.loadSelections();
  },
  methods: {
    exportTable() {
      downloadAsFile({
        dataType: 'table',
        dataSource: 'metrics',
        multiple: true,
        data: this.tableDataForDownload(),
      });
    },
    tableDataForDownload() {
      return cloneDeep(this.selections).map(d => get(d, 'data_points', []));
    },
    getOption(datapoint) {
      return get(datapoint, 'option', '');
    },
    getSelectionPercent(datapoint) {
      return `${get(datapoint, 'selection_percent') || '0'} %`;
    },
    getOptionFrequency(datapoint) {
      return get(datapoint, 'frequency') || '0';
    },
    getTotalFrequency(datapoint) {
      return get(datapoint, 'total_frequency') || '0';
    },
    getQuestion(dataset) {
      return unicodeToASCII(get(dataset, 'data_points.0.question', ''));
    },
    convertToChartFormat(selectionData) {
      return {
        dataset: [{
          data_points: selectionData.filter(dp => dp.option !== TOTAL_LABEL)
            .map(dp => ({ name: dp.option, value: dp.selection_percent })),
        }],
      };
    },
    appendTotals(data) {
      function appendTotal(datapoint) {
        datapoint.data_points.map(dp => {
          if (!dp.option) {
            dp.option = NO_SELECTION_LABEL;
          }
          return dp;
        });
        datapoint.data_points.push({
          question: get(datapoint, 'data_points.0.question', ''),
          option: TOTAL_LABEL,
          selection_percent: 100,
          // Intentionally using total_frequency here since we want TOTAL.
          frequency: get(datapoint, 'data_points.0.total_frequency', '0'),
          total_frequency: get(datapoint, 'data_points.0.total_frequency', '0'),
        });
        return datapoint;
      }
      return data.map(d => appendTotal(d));
    },
    selectionsChartData(selectionData) {
      return this.convertToChartFormat(selectionData);
    },
    loadSelections() {
      const periodStart = moment(this.dateRange[0]).unix();
      const periodEnd = moment(this.dateRange[1]).unix();
      if (!(periodStart && periodEnd && this.selectedBreakdownOption)) {
        return;
      }
      const params = {
        period_start: periodStart,
        period_end: moment.unix(periodEnd).endOf('day').unix(),
        filters: this.selectedFilters,
        bucket: this.selectedBreakdownOption.value,
      };
      if (!isEmpty(this.searchTerm)) {
        params.query = this.searchTerm;
      }
      return this.$store
        .dispatch('metrics/getMetric', ['customer_selections', params])
        .then(data => {
          this.loading = false;
          this.selections = this.appendTotals(get(data, 'dataset', []));
        });
    },
    setSelectedFilters(filterName, chosenFilters) {
      this.selectedFilters = dropDownToSelected(chosenFilters, this.selectedFilters, filterName);
    },
    onFilterChange(chosenFilters) {
      this.setSelectedFilters('customer_segments', chosenFilters);
      this.loadSelections();
    },
    handleDatePickerChange(newDateRange) {
      this.dateRange = setToEndOfDay(getNonReactiveDateRange(newDateRange));
    },
    onSegmentHover(segment) {
      this.segmentAlias = get(segment, 'alias', '');
    },
  },
};
</script>

<style lang="scss">
@import "../../../../styles/aiq-variables.scss";

.toolbar {
  .el-button.export {
    height: 32px;
    width: 40px;
    padding: 0;
    margin-left: 5px;

    &:after {
      clear: both;
    }
  }
  .dropdown-filters {
              display: flex;
              align-items: flex-start;
              .dropdown-filter {
                  margin-right: 4px;
                  width: 18em;
                  height: 32px;
              }
       }
}

.insights-selections {
  .chart-container {
    width: 100%;
    max-height: 220px;
    .svg {
      height: 220px;
      justify-content: center;
    }
  }

  .selection {
    th.el-table__cell.question-th {
      background: $aiq-bgc-main-column-list-item-selected;

      .cell {
        background: $aiq-bgc-main-column-list-item-selected;

        .question {
          background: $aiq-bgc-main-column-list-item-selected;
          font-weight: bold;
          color: #fff;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/styles/aiq-mixins.scss";

.content {
  height: 100%;
}

.insights-selections {
    display: flex;
    .selection {
      margin-bottom: 20px;
      @media (max-width: 1200px) {
        .el-row {
          display: block;
        }
      }
    }
}

.toolbar {
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-end;
  .time-range-filter {
    position: relative;
  }
  .flex-spacer {
   margin-right: 5px;
   .search {
     width: 246px;
     }
  }
  .download-button {
     margin-right: 10px;
  }

    .iq-ico-explanation::before {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      margin-top: 0.25rem;
      display: inline-block;
      vertical-align: middle;
    }
}
</style>
