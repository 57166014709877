import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';

export default {
  name : 'tools-tab',
  data() {
    return {
      webchatConfig: null,
      landingPage: { html_document: '' },
    };
  },
  async mounted() {
    this.webchatConfig = await this.$store.dispatch(
      'settings/getSetting',
      { name: 'webchat_configs' },
    );

    this.landingPage = await this.$store.dispatch(
      'settings/getSetting',
      { name: 'webchat_landing_page_configuration' },
    );
  },
  computed: {
    sentryConfig() {
      return get(this.webchatConfig, 'configs.sentry', {});
    },
  },
  methods: {
    async onSentryChange(val) {
      try {
        const payload = cloneDeep(this.webchatConfig);
        set(payload, 'configs.sentry.enabled', val);
        this.webchatConfig = await this.$store.dispatch('settings/updateSetting', {
          name: 'webchat_configs',
          payload,
        });
  
        this.$aiq.notify.success('Updated');
      } catch (err) {
        this.$aiq.notify.error(err.message);
      }
    },
    async onLandingPageChange(val) {
      try {
        const payload = cloneDeep(this.landingPage);
        set(payload, 'html_document', val);
        this.landingPage = await this.$store.dispatch('settings/updateSetting', {
          name: 'webchat_landing_page_configuration',
          payload,
        });
  
        this.$aiq.notify.success('Updated');
      } catch (err) {
        this.$aiq.notify.error(err.message);
      }
    }
  }
};

