<template lang="pug">
.conversation-log
  .created-at-header
    .header-line
    span {{createdAt}}
    .header-line
  .msg-container
    template(v-for="message in messages")
      aiq-tooltip(v-if="showDeletedMessage(message)", 
                  effect="dark", 
                  placement="top-end",
                  :show-after="1000",
                  :content="tooltipMessage(message)", 
                  :disabled="!message.deleted_at" )
        message(:class="{ deleted: !!message.deleted_at }"
                :key="message.id + message.obfuscated"
                :message="message"
                :systemMessagesDisplay="systemMessagesDisplay"
                :disabled="message.disabled"
                :replyitem="message.replyitem"
                :showIcons="getIconVisibilityConfig(message)"
                :callJoined="callJoined"
                :isTimestampAbsolute="isTimestampAbsolute"
                :accountFieldsVisibility="accountFields"
                :connected="customer.connected"
                :showOnlineIndicator="true"
                @obfuscate="onObfuscate"
                @call="$emit('call', $event)"
                @suggestionsClick="selectSuggestions"
                @click="onClickMessage"
                @translate="onTranslate"
                @delete="setMessageToDelete"
                @link="onLink"
                @focusOnInputbox="focusOnInputbox")
  .conversation__info
    .tags
      aiq-tooltip(v-for="tag in conversation.tags"
                  :key="tag.id"
                  effect="dark"
                  content="Tag"
                  placement="top"
                  :show-after="1000")
        aiq-tag {{tag.name}}
    .categories
      aiq-tooltip(v-for="category in conversation.categories"
                  :key="category.id"
                  effect="dark"
                  content="Category"
                  placement="top"
                  :show-after="1000")
        aiq-tag {{category.name}}
    .teams
      aiq-tooltip(v-for="team in conversation.teams"
                  :key="team.id"
                  effect="dark"
                  content="Team"
                  placement="top"
                  :show-after="1000")
        aiq-tag {{team.name}}

  delete-message-confirm(:message="messageToDelete" 
                        @cancel="setMessageToDelete"
                        @confirm="onDelete")  
</template>

<script>
import get from 'lodash/get';
import set from 'lodash/set';
import assign from 'lodash/assign';
import { mapGetters, mapActions, mapState } from 'vuex';
import DeleteMessageConfirm from './components/DeleteMessageConfirm';
import moment from 'moment';
import LanguageDetect from 'languagedetect';
import * as log from 'loglevel';

const MIN_ACCEPTABLE_PROBABILITY = 0.22;
const TAG = 'ConversationLog';

export default {
  name: 'conversationLog',
  components: {
    DeleteMessageConfirm
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
    callJoined: {
      type: Boolean,
    },
    conversationId: {
      type: String,
      required: true,
    },
  },
  inject: ['focusOnInputbox'],
  data() {
    return {
      lngDetector: null,
      messageToDelete: null,
    };
  },
  created() {
    this.lngDetector = new LanguageDetect();
    this.lngDetector.setLanguageType('iso2');
  },
  computed: {
    ...mapGetters({
      showSuggestIcons: 'conversations/existingItemSuggestions',
      conversations: 'conversations/conversations',
    }),
    ...mapState({
      agentProfile: state => state.agent.profile,
      systemMessagesDisplay: state => state.conversations.systemMessagesDisplay,
      translationRecords: state => state.translation.records,
      COBROWSE_AUDIT_VIEW: state => state.featureFlags.FEATURE_FLAGS.COBROWSE_AUDIT_VIEW,
      isTimestampAbsolute: state => state.conversations.isTimestampAbsolute,
      accountFields: state => state.configs.config.account_fields,
    }),
    conversation() {
      const conversation = this.conversations.find(c => c.id === Number(this.conversationId)) || {};
      return conversation;
    },
    createdAt() {
      const time = this.conversation.created_at;
      const useCalendar = moment().isSame(moment(time), 'day') ||
        moment().isSame(moment(time).subtract(1, 'days'));

      return useCalendar
        ? moment(time).calendar()
        : moment(time).format('lll');
    },
  },
  methods: {
    ...mapActions({
      selectSuggestions: 'conversations/selectSuggestions',
      obfuscateMessage: 'conversations/obfuscateMessage',
      deleteMessage: 'conversations/deleteMessage',
    }),
    getIconVisibilityConfig(message) {
      // TODO: feature flag
      return assign({
        documents: false,
        assets: false,
        obfuscation: this.canView('/conversations/message/obfuscation'),
        translation: this.canTranslate(message),
        delete: this.canDelete(message),
      }, this.showSuggestIcons[message.id]);
    },
    showDeletedMessage(message) {
      return !message.deleted_at || this.canView('/conversations/message/audit');
    },
    tooltipMessage({ deleted_by, deleted_by_agent, payload }) {
      const { expiration_date } = payload;
      const botId = get(this.$store.getters['conversations/bot'], 'id');
      if (expiration_date && deleted_by === botId) return `Expired ${moment(expiration_date).format('YYYY-MM-DD')}`;
      return `Deleted by ${get(deleted_by_agent, 'fullName', '')}`;
    },
    onObfuscate(message) {
      return this.$aiq.confirm(
        'Message Masking Confirmation',
        'Message masking is permanent. <br> The message will be replaced with asterisks ( * ) <br> Do you want to proceed?',
        { confirmButtonText: 'Proceed' },
      )
        .then(confirm => {
          if (confirm) {
            this.obfuscateMessage(message)
              .then(() => this.$aiq.notify.success('Successful'))
              .catch(() => this.$aiq.notify.error('Unable to mask the message'));
          }
        }, _cancel => {
        });
    },
    canTranslate(message) {
      if (!this.canView('/conversations/message/translation') || message.deleted_at) {
        return false;
      }
      const message_type = get(message, 'payload.message_type', '');
      const contentPath = message_type ? `payload.${message_type}.content` : 'payload.content';
      const content = get(message, contentPath, '');
      const obfuscated = get(message, 'obfuscated', false);
      const translated = get(message, 'translated', false);
      const detections = this.lngDetector.detect(content);
      const detection = detections.find(([x]) => x === 'en');

      // Determine that the content needs to be translated based on
      // the probability of 20% or less it's in english
      const canTranslate = (detection && (detection[1] < MIN_ACCEPTABLE_PROBABILITY));
      // We show icon if message have content and is not obfuscated and
      // was not possible determine the language or
      // the content was already translated or
      // the probability of 20% or less it's in english
      const show = content && !obfuscated && (!detection || translated || canTranslate);
      return {
        show,
        icon: translated ? 'iq-ico-undo' : 'iq-ico-translation',
      };
    },
    canDelete({ sender_id, sender_type, deleted_at }) {
      return this.canView('/conversations/message/delete') &&
            !deleted_at &&
            sender_type === 'agents' &&
            sender_id === this.agentProfile.id;
    },
    trySaveCustomerLanguageCode(languageCode, sender_type) {
      const currentLanguageCode = get(this.customer, 'profile.language_code', '');
      if (languageCode === currentLanguageCode || sender_type !== 'customers') {
        return;
      }
      set(this.customer, 'profile.language_code', languageCode);
      this.$store.dispatch('conversations/updateCustomer', [
        this.customer.id,
        { ...this.customer },
      ]);
    },
    setMessageToDelete(message = null) {
      this.messageToDelete = message;
    },
    async onTranslate(message) {
      try {
        const id = get(message, 'id', 0);
        const message_type = get(message, 'payload.message_type', '');
        const path = message_type ? `payload.${message_type}` : 'payload';
        const content = get(message, `${path}.content`, '');
        const originalContent = get(message, `${path}.originalContent`, '');
        const translated = get(message, 'translated', false);
        if (translated) {
          const record = this.translationRecords.find(t => t.id === id);
          set(message, `${path}.content`, record.q);
          message['translated'] = false;
          delete get(message, path).translation;
          return;
        }
        const { translation, language } = await this.$store.dispatch('translation/translate', { id, q: content, target: 'en' });
        this.trySaveCustomerLanguageCode(get(language, 'code', ''), message.sender_type);
        if (get(message, `${path}.content`, '') === translation) {
          this.$aiq.notify.info('Translated to the same content');
        }
        set(message, `${path}.content`, originalContent || translation);
        message['translated'] = true;
        get(message, path)['translation'] = language ? { from: language.code, to: 'en' } : null;
      } catch (err) {
        this.$aiq.notify.error('Unable to translate content');
      }
    },
    async onDelete(message) {
      try {
        this.setMessageToDelete();
        const payload = { conversationId: message.conversation_id, messageId: message.id };
        await this.deleteMessage(payload);
        this.$aiq.notify.success('Successful');
      } catch (err) {
        log.error(err);
        this.$aiq.notify.error('Unable to delete the message');
      }
    },
    async onClickMessage(msg) {
      // POC && Cobrowse audit view
      if (this.COBROWSE_AUDIT_VIEW && get(msg, 'payload.system.content', '').startsWith('Cobrowse Session: ')) {
        const str = get(msg, 'payload.system.content', '');
        const match = str.match(/Cobrowse Session: ([a-zA-Z0-9]+) by ([a-zA-Z0-9@.]+)$/i);
        const token = match[1];
        const email = match[2];

        const url = await this.$store.dispatch('agent/getCobrowseAuditUrl', {
          identity: email,
          session: token,
        });

        log.debug(TAG, 'url', url);

        window.open(url, '_blank', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no');
      }
    },

    onLink({ url /* type */ }) { // type can be hyperlink or document at this moment.
      window.open(url, '_blank'); // open anything with new tab
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../styles/aiq-mixins.scss";
@import "../../../../../../styles/media-queries.scss";

.created-at-header {
  display: flex;
  height: 14px;
  margin-top: 10px;
  margin-bottom: 20px;

  @include mobile {
    margin-bottom: 7px;
  }

  .header-line {
    border-bottom: 1px solid #eee;
    flex-grow: 1;
    height: 10px;
  }

  span {
    color: #45494f;
    font-size: 14px;
    font-family: -apple-system,BlinkMacSystemFont,Helvetica,Arial,sans-serif;
    margin: 0 16px;
    opacity: 0.75;
  }
}

.conversation__info {
  display: flex;
  @include space-between-h(4px);

  > div {
    @include space-between-h(4px);

    cursor: default;
  }
}
</style>

<style lang="scss">
.tags {
  .el-tag {
    background-color: #fbeefd;
    color: #dd55f2;
  }
  }
.categories {
  .el-tag {
    background-color: #fff8ed;
    color: #f29900;
  }
}
.teams {
  .el-tag {
    background-color: #e3ecff;
    color: #5a88de;
  }
}
.danger-button {
  background-color: #ff4949 !important;
  border-color: #ff4949 !important;
}
.message-list_item.deleted {
  opacity: .5;
}
</style>
