<template lang="pug">
    aiq-form.alert-messages-container
      label {{ $t(`${baseTranlationPath}.title`) }}
      .field-label {{ $t(`${baseTranlationPath}.banner_description`) }}
      .field-label {{ $t(`${baseTranlationPath}.interstitial_description`) }}

      .form-container
        aiq-form-item.sub-field(v-if="alertMessages.length")
          label.sub-label.small-font {{ $t(`${baseTranlationPath}.select_existing`) }}
          aiq-select(placeholder="Select" :model-value="alertMessage.id" clearable @change="onSelectExisting")
            aiq-option(v-for="item in alertMessages"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id")

        aiq-form-item.sub-field
          label.sub-label.small-font {{ $t(`${baseTranlationPath}.message_type`) }}
          aiq-select(v-model="alertMessage.alert_type" 
                    key="alertType"
                    placeholder="Select" 
                    name="alertMessage.alert_type" 
                    :disabled="!!alertMessage.id"
                    clearable
                    @change="onSelectAlertType"
                    @clear="cleanForm()")
            aiq-option(v-for="type in alertTypes"
                      :key="type.key"
                      :label="type.label"
                      :value="type.key")

        div(v-if="alertMessage.alert_type")
          aiq-form-item.sub-field(v-if="alertMessage.alert_type === ALERT_TYPE_KEYS.BANNER" :class="{'is-error': v$.alertMessage.schedule_type.$error }")
            label.sub-label.small-font {{ $t(`${baseTranlationPath}.message_frequency`) }}
            aiq-select(v-model="alertMessage.schedule_type" 
                      key="scheduleType"
                      placeholder="Select" 
                      name="alertMessage.schedule_type"
                      clearable
                      @clear="cleanForm(['alert_type'])")
              aiq-option(v-for="type in scheduleTypes"
                        :key="type.key"
                        :label="type.label"
                        :value="type.key")
            span.text-danger(v-show="v$.alertMessage.schedule_type.$error") {{ v$.alertMessage.schedule_type.$errors[0]?.$message }}

          div(v-if="alertMessage.schedule_type")
            aiq-form-item.sub-field(:class="{'is-error': v$.localWebchatConfigsStrings.banner_and_interstitial_messages[alertMessage.payload.message_langkey].$error}")
              label.sub-label.small-font {{ $t(`${baseTranlationPath}.message`) }}
              aiq-input(v-model="localWebchatConfigsStrings.banner_and_interstitial_messages[alertMessage.payload.message_langkey]"
                        key="message"
                        placeholder="Define message"
                        size="small"
                        type="textarea"
                        :status="true ? 'error' : ''"
                        :name="`alertMessage.payload.${alertMessage.payload.message_langkey}`")
              span.text-danger(v-show="v$.localWebchatConfigsStrings.banner_and_interstitial_messages[alertMessage.payload.message_langkey].$error") 
                | {{ v$.localWebchatConfigsStrings.banner_and_interstitial_messages[alertMessage.payload.message_langkey].$errors[0]?.$message }}

            aiq-form-item.sub-field(:class="{'is-error': v$.alertMessage.segments.$error}")
              label.sub-label.small-font {{ $t(`${baseTranlationPath}.segments`) }}
              aiq-select(v-model="alertMessage.segments" 
                        key="segments"
                        multiple 
                        name="alertMessage.segments"
                        placeholder="Select" 
                        :reserve-keyword="false")
                aiq-option(v-for="item in segments"
                          :key="item"
                          :label="item"
                          :value="item")
              span.text-danger(v-show="v$.alertMessage.segments.$error") {{ v$.alertMessage.segments.$errors[0]?.$message }}

            aiq-form-item.sub-field(v-if="isRecurringSchedule" :class="{'is-error': v$.alertMessage.frequency.$error }")
              label.sub-label.small-font {{ $t(`${baseTranlationPath}.displays`) }}
              aiq-select(v-model="alertMessage.frequency" 
                        key="frequency"
                        placeholder="Select" 
                        @change="resetDisplaysDays" 
                        name="alertMessage.frequency")
                aiq-option(v-for="frequency in frequencies"
                          :key="frequency.key"
                          :label="frequency.label"
                          :value="frequency.key")
              span.text-danger(v-show="v$.alertMessage.frequency.$error") {{ v$.alertMessage.frequency.$errors[0]?.$message }}

            aiq-form-item.sub-field(v-if="isWeeklyFrequency" :class="{'is-error': v$.alertMessage.days.$error }")
              label.sub-label.small-font {{ $t(`${baseTranlationPath}.displays_days`) }}
              aiq-checkbox-group(v-model="alertMessage.days"
                                key="days"
                                size="small" 
                                name="alertMessage.days")
                aiq-tooltip(v-for="day in daysOfWeek" 
                            effect="dark", 
                            placement="top", 
                            :key="day.key"
                            :content="day.tooltip",
                            :show-after="500")
                  aiq-checkbox-button(:label="day.key" 
                                      :key="day.key") {{ day.label }}
              span.text-danger(v-show="v$.alertMessage.days.$error") {{ v$.alertMessage.days.$errors[0]?.$message }}

            aiq-form-item.sub-field
              label.sub-label.small-font {{ $t(`${baseTranlationPath}.effective_date_time`) }} 
                span.field-label ({{ $t(`${baseTranlationPath}.display_in`, { time_zone: currentTimeZone, defaultValue: `Time appears in ${currentTimeZone}`}) }})
              aiq-row(:gutter="20")
                aiq-col(:sm="12")
                  aiq-form-item(:class="{'is-error': v$.alertMessage.effective_date.$error }")
                    aiq-date-picker.el-input__inner(v-model="alertMessage.effective_date"
                                                    ref="effectiveDate"
                                                    key="effectiveDate"
                                                    type="date"
                                                    popper-class="date-picker-popup"
                                                    placeholder="MM/DD/YYYY"
                                                    :disabled-date="datePickerOptions.disabledDate"
                                                    :clearable="false"
                                                    format="MM/DD/YYYY"
                                                    name="alertMessage.effective_date"
                                                    @change="onDateChange($event, 'effective_date', 'effectiveTime')")
                aiq-col(:sm="12")
                  aiq-time-picker.el-input__inner(v-model="effectiveTime"
                                                  :disabled="!alertMessage.effective_date"
                                                  :clearable="false"
                                                  placeholder="HH:MM",
                                                  name="effectiveTime"
                                                  @change="onTimeChange($event, 'effective_date')")
              span.text-danger(v-show="v$.alertMessage.effective_date.$error") 
                | {{ v$.alertMessage.effective_date.$errors[0]?.$message }}

            aiq-form-item.sub-field
              label.sub-label.small-font {{ $t(`${baseTranlationPath}.expiration_date_time`) }} 
                span.field-label ({{ $t(`${baseTranlationPath}.display_in`, { time_zone: currentTimeZone, defaultValue: `Time appears in ${currentTimeZone}`}) }})
              aiq-row(:gutter="20")
                aiq-col(:sm="12")
                  aiq-form-item(:class="{'is-error': v$.alertMessage.expiration_date.$error }")
                    aiq-date-picker.el-input__inner(v-model="alertMessage.expiration_date"
                                                    ref="expirationDate"
                                                    key="expirationDate"
                                                    type="date"
                                                    popper-class="date-picker-popup"
                                                    placeholder="MM/DD/YYYY"
                                                    :disabled-date="datePickerOptions.disabledDate"
                                                    :clearable="false"
                                                    format="MM/DD/YYYY"
                                                    name="alertMessage.expiration_date"
                                                    @change="onDateChange($event, 'expiration_date', 'expirationTime')")
                aiq-col(:sm="12")
                  aiq-time-picker.el-input__inner(v-model="expirationTime"
                                                  :disabled="!alertMessage.expiration_date"
                                                  :clearable="false"
                                                  placeholder="HH:MM",
                                                  name="expirationTime"
                                                  @change="onTimeChange($event, 'expiration_date')")
              span.text-danger(v-show="v$.alertMessage.expiration_date.$error") 
                | {{ v$.alertMessage.expiration_date.$errors[0]?.$message }}

            aiq-form-item.sub-field(v-if="isRecurringSchedule")
              label.sub-label.small-font {{ $t(`${baseTranlationPath}.display_hours`) }} 
                span.field-label ({{ $t(`${baseTranlationPath}.display_in`, { time_zone: currentTimeZone, defaultValue: `Time appears in ${currentTimeZone}`}) }})
              aiq-row(:gutter="20")
                aiq-col(:sm="12")
                  aiq-form-item(:class="{'is-error': v$.alertMessage.start_time.$error }")
                    aiq-time-picker.el-input__inner(v-model="alertMessage.start_time"
                                                    ref="startTime"
                                                    :placeholder="$t(`${baseTranlationPath}.from`, { format: '(HH:MM)' })",
                                                    name="alertMessage.start_time")
                    span.text-danger(v-show="v$.alertMessage.start_time.$error") 
                      | {{  v$.alertMessage.start_time.$errors[0]?.$message }}

                aiq-col(:sm="12")
                  aiq-form-item(:class="{'is-error': v$.alertMessage.end_time.$error }")
                    aiq-time-picker.el-input__inner(v-model="alertMessage.end_time"
                                                    ref="endTime"
                                                    :placeholder="$t(`${baseTranlationPath}.to`, { format: '(HH:MM)'})",
                                                    name="alertMessage.end_time")
                    span.text-danger(v-show="v$.alertMessage.end_time.$error") 
                      | {{  v$.alertMessage.end_time.$errors[0]?.$message }}

            aiq-form-item.sub-field(:class="{'is-error': v$.alertMessage.name.$error }")
              label.sub-label.small-font {{ $t(`${baseTranlationPath}.message_name`, { name: $t(`${baseTranlationPath}.alert_types.${alertMessage.alert_type}`), defaultValue: `Chat ${$t(`${baseTranlationPath}.alert_types.${alertMessage.alert_type}`)} Message Name`}) }}
              aiq-input(v-model="alertMessage.name" 
                        key="name"
                        placeholder="Name" 
                        size="small" 
                        name="alertMessage.name"
                        :disabled="!!alertMessage.id")
              span.text-danger(v-show="v$.alertMessage.name.$error") {{  v$.alertMessage.name.$errors[0]?.$message }}

            aiq-form-item.sub-field(v-if="!!alertMessage.id")
              aiq-button.button-delete(type="danger"
                                      @click="onDeleteAlertMessage") {{ $t(`${baseTranlationPath}.delete`) }}
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import times from 'lodash/times';
import random from 'lodash/random';
import get from 'lodash/get';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf, minValue, maxValue } from '@vuelidate/validators';


const BASE_TRANSLATION_PATH = 'settings_tab.channels_tab.webchat_configuration.banner_and_interstitial_messages';

const ALERT_TYPE_KEYS = {
  BANNER: 'banner',
  INTERSTITIAL: 'interstitial',
};

const SCHEDULE_TYPE_KEYS = {
  ONE_TIME: 'one-time',
  RECURRING: 'recurring',
};

export default {
  name: 'AlertMessages',
  setup () {
    return { v$: useVuelidate() };
  },
  validations () {
    return {
      alertMessage: {
        alert_type: { $autoDirty: true },
        schedule_type: {  
          requiredIf: requiredIf(this.alertMessage.alert_type),
          $autoDirty: true 
        },
        segments:  {  
          requiredIf: requiredIf(this.alertMessage.schedule_type),
          $autoDirty: true 
        },
        frequency: {  
          requiredIf: requiredIf(this.isRecurringSchedule),
          $autoDirty: true 
        },
        days: {  
          requiredIf: requiredIf(this.isWeeklyFrequency),
          $autoDirty: true 
        },
        effective_date: {  
          requiredIf: requiredIf(this.alertMessage.schedule_type),
          ...(this.alertMessage.expiration_date ? { maxValue: maxValue(this.alertMessage.expiration_date) } : {}),
          $autoDirty: true 
        },
        expiration_date: {  
          requiredIf: requiredIf(this.alertMessage.schedule_type),
          ...(this.alertMessage.effective_date ? { minValue: minValue(this.alertMessage.effective_date) } : {}),
          $autoDirty: true 
        },
        start_time: {  
          requiredIf: requiredIf(this.isRecurringSchedule),
          ...(this.alertMessage.end_time ? { maxValue: maxValue(this.alertMessage.end_time) } : {}),
          $autoDirty: true 
        },
        end_time: {  
          requiredIf: requiredIf(this.isRecurringSchedule),
          ...(this.alertMessage.start_time ? { minValue: minValue(this.alertMessage.start_time) } : {}),
          $autoDirty: true 
        },
        payload: {
          message_langkey: {  
            requiredIf: requiredIf(this.alertMessage.schedule_type),
            $autoDirty: true 
          },
        },
        name: { 
          requiredIf: requiredIf(this.alertMessage.schedule_type), 
          $autoDirty: true 
        },
      },
      localWebchatConfigsStrings: {
        banner_and_interstitial_messages: {
          [this.alertMessage.payload.message_langkey]: {
            requiredIf: requiredIf(this.alertMessage.schedule_type),
            $autoDirty: true 
          }
        }
      }
    };
  },
  props: {
    localWebchatConfigsStrings: {
      type: Object,
      default: () => {},
    },
    segments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ALERT_TYPE_KEYS,
      alertMessage: cloneDeep(this.$store.getters['alertMessages/alertMessageModel']),
      expirationTime: null,
      effectiveTime: null,
    };
  },
  watch: {
    alertMessage(newValue) {
      if (isEqual(newValue, this.$store.getters['alertMessages/alertMessageModel'])) {
        this.expirationTime = null;
        this.effectiveTime = null;
      }
    },
    updateAlertMessagePending(newValue) {
      // If update was successful, reset the local alert message
      if (!newValue) {
        this.cleanForm();
      }
    },
    createAlertMessagePending(newValue) {
      // If create was successful, reset the local alert message
      if (!newValue) {
        this.cleanForm();
      }
    }
  },
  computed: {
    ...mapState({
      createAlertMessagePending: state => state.alertMessages.createPending,
      updateAlertMessagePending: state => state.alertMessages.updatePending,
      alertMessages: state => state.alertMessages.alertMessages || [],
      webchatLanguageStrings: state => state.configs.language_strings,
    }),
    baseTranlationPath() {
      return BASE_TRANSLATION_PATH;
    },
    currentTimeZone() {
      const timeZone = moment.tz.guess();
      const abbreviation = moment.tz(timeZone).format('z');
      return abbreviation;
    },
    daysOfWeek() {
      return [
        { tooltip: this.$t('week_days.sunday.long_name'), key: 0, label: this.$t('week_days.sunday.short_name') },
        { tooltip: this.$t('week_days.monday.long_name'), key: 1, label: this.$t('week_days.monday.short_name') },
        { tooltip: this.$t('week_days.tuesday.long_name'), key: 2, label: this.$t('week_days.tuesday.short_name') },
        { tooltip: this.$t('week_days.wednesday.long_name'), key: 3, label: this.$t('week_days.wednesday.short_name') },
        { tooltip: this.$t('week_days.thursday.long_name'), key: 4, label: this.$t('week_days.thursday.short_name') },
        { tooltip: this.$t('week_days.friday.long_name'), key: 5, label: this.$t('week_days.friday.short_name') },
        { tooltip: this.$t('week_days.saturday.long_name'), key: 6, label: this.$t('week_days.saturday.short_name') },
      ];
    },
    alertTypes() {
      return [
        {
          key: ALERT_TYPE_KEYS.BANNER,
          label: this.$t(`${BASE_TRANSLATION_PATH}.alert_types.banner`),
        },
        {
          key: ALERT_TYPE_KEYS.INTERSTITIAL,
          label: this.$t(`${BASE_TRANSLATION_PATH}.alert_types.interstitial`),
        },
      ];
    },
    scheduleTypes() {
      return [
        {
          key: SCHEDULE_TYPE_KEYS.ONE_TIME,
          label: this.$t(`${BASE_TRANSLATION_PATH}.schedule_types.one_time`),
        },
        // TODO: Temporal disabling recurring till tests finish
        // {
        //   key: SCHEDULE_TYPE_KEYS.RECURRING,
        //   label: this.$t(`${BASE_TRANSLATION_PATH}.schedule_types.recurring`),
        // },
      ];
    },
    frequencies() {
      return [
        {
          key: 'daily',
          label: this.$t(`${BASE_TRANSLATION_PATH}.frequencies.daily`),
        },
        {
          key: 'weekly',
          label: this.$t(`${BASE_TRANSLATION_PATH}.frequencies.weekly`),
        },
        {
          key: 'monthly',
          label: this.$t(`${BASE_TRANSLATION_PATH}.frequencies.monthly`),
        },
      ];
    },
    isWeeklyFrequency() {
      return this.alertMessage.frequency === 'weekly';
    },
    isRecurringSchedule() {
      return this.alertMessage.schedule_type === 'recurring';
    },
    datePickerOptions() {
      return {
        disabledDate: (time) => time.getTime() < moment().subtract(1, 'days').endOf('day'),
      };
    },
  },
  methods: {
    get,
    startCase,
    setAlertMessage(model) {
      this.alertMessage = model;
    },
    resetDisplaysDays() {
      this.alertMessage.days = [];
    },
    cleanForm(pickProps) {
      const model = { ...this.$store.getters['alertMessages/alertMessageModel'], ...pick(this.alertMessage, pickProps) };
      this.setAlertMessage(model);
      this.expirationTime = null;
      this.effectiveTime = null;
      if (this.v$.$reset && !pickProps) {
        this.v$.$reset();
      }
    },
    deleteConfirm() {
      return new Promise(resolve => {
        this.$aiq.confirm(
          this.$t(`${BASE_TRANSLATION_PATH}.delete_confirm.title`),
          this.$t(`${BASE_TRANSLATION_PATH}.delete_confirm.message`, { name: this.alertMessage.name,
            defaultValue: `Are you sure you want to delete this ${this.alertMessage.name}?` }), {
            cancelButtonText: this.$t(`${BASE_TRANSLATION_PATH}.delete_confirm.cancel_button`),
            confirmButtonText: this.$t(`${BASE_TRANSLATION_PATH}.delete_confirm.confirm_button`),
          },
        )
          .then(() => resolve(true), () => resolve(false));
      });
    },
    onSelectAlertType(value) {
      if (!this.alertMessage.id) {
        this.alertMessage.payload.message_langkey = times(8, () => random(35).toString(36)).join(''); // eslint-disable-line no-magic-numbers
      }
      if (value === ALERT_TYPE_KEYS.INTERSTITIAL) {
        this.alertMessage.schedule_type = SCHEDULE_TYPE_KEYS.ONE_TIME;
      } else {
        this.alertMessage.schedule_type = undefined;
      }
    },
    onSelectExisting(alertMessageId) {
      let alertMessage = this.alertMessages.find(x => x.id === alertMessageId);
      if (alertMessage) {
        this.localWebchatConfigsStrings.banner_and_interstitial_messages =
          cloneDeep(get(this.webchatLanguageStrings, 'webchat.banner_and_interstitial_messages', {}));
        alertMessage = cloneDeep(alertMessage);
        alertMessage.effective_date = moment(alertMessage.effective_date).toDate();
        alertMessage.expiration_date = moment(alertMessage.expiration_date).toDate();
        if (alertMessage.start_time) {
          alertMessage.start_time = moment(`${moment().format('YYYY-MM-DD')} ${alertMessage.start_time}`).toDate();
        }
        if (alertMessage.end_time) {
          alertMessage.end_time = moment(`${moment().format('YYYY-MM-DD')} ${alertMessage.end_time}`).toDate();
        }
        this.effectiveTime = moment(alertMessage.effective_date).toDate();
        this.expirationTime = moment(alertMessage.expiration_date).toDate();
        this.setAlertMessage(alertMessage);
      } else {
        this.cleanForm();
      }
    },
    onDateChange(date, dateProp, timeProp) {
      const isToday = moment(date).isSame(moment(), 'day');
      if (!this[timeProp]) {
        let currentDate = date;
        if (timeProp === 'effectiveTime' && isToday) {
          currentDate = moment().toDate();
        } else if (timeProp === 'expirationTime') {
          currentDate = moment(currentDate).endOf('day').toDate();
        }
        this[timeProp] = currentDate;
      }
      // Extract date from the sourceDate
      const sourceDate = moment(date).format('YYYY-MM-DD');
      // Combine the extracted date with the time part of the targetTime
      this.alertMessage[dateProp] = moment(`${sourceDate} ${moment(this[timeProp]).format('HH:mm:ss')}`).toDate();
    },
    onTimeChange(date, dateProp) {
      if (!this.alertMessage[dateProp] || !date) {
        return;
      }
      // Extract time from the sourceDate
      const sourceTime = moment(date).format('HH:mm:ss');
      // Combine the extracted time with the date part of the targetDate
      this.alertMessage[dateProp] = moment(`${moment(this.alertMessage[dateProp]).format('YYYY-MM-DD')} ${sourceTime}`).toDate();
    },
    async onDeleteAlertMessage() {
      try {
        const response = await this.deleteConfirm();
        if (response) {
          await this.$store.dispatch('alertMessages/deleteAlertmessage', this.alertMessage.id);
          await this.$store.dispatch('alertMessages/getAlertmessagesList');
          this.cleanForm();
          this.$aiq.notify.success(`Chat ${startCase(this.alertMessage.alert_type)} Message has been deleted.`);
        }
      } catch (ex) {
        this.$aiq.notify.error(`Unable to delete Chat ${startCase(this.alertMessage.alert_type)} Message.`);
      }
    },
  },
  created() {
    this.cleanForm();
  },
  async mounted() {
    await this.$store.dispatch('alertMessages/getAlertmessagesList');
  },
};
</script>

<style lang="scss">
@import "../../styles/aiq-variables.scss";

.alert-messages-container {
  width: 482px;

  .el-date-editor {
    width: 100%;
  }

  .el-form-item.sub-field {
    margin: 8px 0;
    .el-form-item__content {
      line-height: 20px !important;
      display: block;
    }
  }

  .field-label {
    color: #475669;
    letter-spacing: 0;
    font-family: $aiq-font-family;
    font-size: 14px;
    font-weight: 300;
  }
  .el-date-editor.el-input__inner.is-disabled {
      background-color: rgb(238, 241, 246);
      border-color: rgb(209, 219, 229);
  }
  .button-delete {
    float: right;
    margin-right: 0px;
  }
  .text-danger {
    color: #F56C6C;
    font-size: 12px;
  }
}
</style>
