import { createNamespacedHelpers, mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import actionItem from '@/pages/IQTools/Partials/ActionItem/ActionItem.vue';
import { appendUnitType } from '@/libs';
import { CHANNEL_NAMES, CHANNEL_NAMES_ALL } from '@/constants';

const { mapMutations } = createNamespacedHelpers('intents/formIntent');
const confirmOptions = {
  title: 'Replace Existing Channel Actions',
  content: 'This action will replace all existing Channel Actions. Are you sure you want to do this?',
};
export default {
  name: 'intent-channel-actions',
  components: {
    actionItem,
  },
  data() {
    return {
      localChannelActions: [],
      hasChannelActionAll: false,
      isNewFormToggleOn: false,
      newChannelSelections: [],
    };
  },
  computed: {
    ...mapState({
      formIntent: state => state.intents.formIntent,
    }),
    channelActionsExist() {
      return this.localChannelActions.length > 0;
    },
    isSetWithAllChannels() {
      return get(this, 'localChannelActions[0].channels[0]') === CHANNEL_NAMES_ALL;
    },
    shouldShowNewForm() {
      return !this.channelActionsExist || this.isNewFormToggleOn;
    },
    selectedChannelNames() {
      const channelNameGroups = this.localChannelActions.map(channelAction => channelAction.channels);
      return channelNameGroups.reduce((acc, channelNames) => acc.concat(channelNames), []);
    },
    unselectedChannelNames() {
      return difference(CHANNEL_NAMES, this.selectedChannelNames);
    },
  },
  created() {
    this.localChannelActions = cloneDeep(this.formIntent.channel_actions);
  },
  updated() {
    // Selection element does not return which item is selected, so must track like this
    this.setChannelActionAllState();
  },
  methods: {
    ...mapMutations([
      'UPDATE_CHANNEL_ACTIONS',
    ]),
    deleteAction(actionIndex, channelActionIndex) {
      this.localChannelActions[channelActionIndex].actions.splice(actionIndex, 1);
    },

    deleteChannelGroup(removedChannelIndex) {
      this.localChannelActions.splice(removedChannelIndex, 1);
    },
    handleAllChannelsSelected(allChannelActionIndex) {
      if (this.channelActionsExist && !this.hasChannelActionAll) {
        // Need to save a backup state to revert to because v-model will mutate variable regardless
        const previousChannelActions = cloneDeep(this.localChannelActions);
        this.$aiq.confirm(confirmOptions.title, confirmOptions.content).then(() => {
          let actions = [];

          if (allChannelActionIndex >= 0) {
            /* eslint-disable-next-line prefer-destructuring */
            actions = this.localChannelActions[allChannelActionIndex].actions;
          }
          this.updateWithAllChannelsSelected(actions);
        }, () => {
          this.localChannelActions = previousChannelActions;
        });
      }
    },
    handleAllChannelsUnselected(allChannelActionIndex = 0) {
      const selectedChannelNames = this.localChannelActions[allChannelActionIndex].channels;
      const channelNamesNoAll = selectedChannelNames.filter(name => name !== CHANNEL_NAMES_ALL);

      this.localChannelActions[allChannelActionIndex].channels = channelNamesNoAll;
      this.hasChannelActionAll = false;
    },
    onSelect(selectedChannelName, formChannelActionIndex) {
      if (selectedChannelName.includes(CHANNEL_NAMES_ALL)) {
        /**
         * Need to exclude the case of the All channel as the only selected channel (length = 1)
         * which occurs when the dropdown is reselected on All selection update
         */
        if (this.hasChannelActionAll && selectedChannelName.length > 1) {
          this.handleAllChannelsUnselected(formChannelActionIndex);
        } else {
          this.handleAllChannelsSelected(formChannelActionIndex);
        }
      }
    },
    replaceTheAllChannel(selectedChannelName) {
      const previousChannelActions = cloneDeep(this.localChannelActions);
      this.$aiq.confirm(confirmOptions.title, confirmOptions.content).then(() => {
        const allChannelAction = {
          channels: [selectedChannelName],
          actions: [],
        };

        this.hasChannelActionAll = false;
        this.UPDATE_CHANNEL_ACTIONS([allChannelAction]);
      }, () => {
        this.localChannelActions = previousChannelActions;
      });
    },
    // TODO (Gabe) Perhaps move this in a watcher if needed after upgrading Element UI
    setChannelActionAllState() {
      for (const channelAction of this.localChannelActions) {
        // loop through just in case the All channel isn't the only selected channel name
        for (const channelName of channelAction.channels) {
          if (channelName === CHANNEL_NAMES_ALL) {
            this.hasChannelActionAll = true;
            return;
          }
        }
      }
      this.hasChannelActionAll = false;
    },
    toggleNewChannelAction() {
      this.isNewFormToggleOn = !this.isNewFormToggleOn;
    },
    updateChannelActions(evt, index) {
      const { actions } = this.localChannelActions[index];

      if (evt.added) {
        // Putting in array to conform to appendUnitType
        const addedAction = actions[evt.added.newIndex];
        const action = [addedAction];
        appendUnitType(action);
      }
    },
    updateWithAllChannelsSelected(actions) {
      const allChannelAction = {
        channels: [CHANNEL_NAMES_ALL],
        actions,
      };

      this.hasChannelActionAll = true;
      this.UPDATE_CHANNEL_ACTIONS([allChannelAction]);
    }
  },
  watch: {
    formIntent: {
      deep: true,
      handler(newFormIntent) {
        if (!isEqual(newFormIntent.channel_actions, this.localChannelActions)) {
          this.localChannelActions = cloneDeep(newFormIntent.channel_actions);
        }
      },
    },
    'formIntent.id': {
      handler() {
        this.isNewFormToggleOn = false;
        this.newChannelSelections = [];
      },
    },
    localChannelActions: {
      deep: true,
      handler(newChannelActions) {
        this.UPDATE_CHANNEL_ACTIONS(newChannelActions);
      },
    },
    newChannelSelections: {
      deep: true,
      handler(newChannelSelections, oldChannelSelections) {
        if (isEmpty(oldChannelSelections) && !isEmpty(newChannelSelections)) {
          if (newChannelSelections[0] === CHANNEL_NAMES_ALL) {
            this.handleAllChannelsSelected();
          } else if (this.isSetWithAllChannels) {
            this.replaceTheAllChannel(newChannelSelections[0]);
          } else {
            const newChannelAction = {
              channels: newChannelSelections,
              actions: [],
            };
            this.localChannelActions.push(newChannelAction);
          }

          this.isNewFormToggleOn = false;
          this.newChannelSelections = [];
        }
      },
    },
  }
};
