// TODO (Gabe) Create function for spyifying entire module

export const createSpies = params => {
  const { obj, methodNames } = params;
  methodNames.forEach(methodName => sinon.spy(obj, methodName));
};

export const namespacify = modules => {
  // Only namespaces shallowly
  for (const name in modules) {
    if ({}.hasOwnProperty.call(modules, name)) {
      modules[name].namespaced = true;
    }
  }

  return modules;
};

export const resetSpies = params => {
  const { obj, methodNames } = params;

  if (methodNames) {
    methodNames.forEach(methodName => obj[methodName].resetHistory());
  } else {
    for (const methodName in obj) {
      if ({}.hasOwnProperty.call(obj, methodName)) {
        obj[methodName].resetHistory();
      }
    }
  }
};

/**
 * TODO (Gabe) - update to use dot notation path to apply
 * spies to nested properties
 */
export const spyifyProps = obj => {
  for (const prop in obj) {
    if ({}.hasOwnProperty.call(obj, prop)) {
      obj[prop] = sinon.spy();
    }
  }

  return obj;
};
