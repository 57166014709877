<template lang='pug'>
.prechat-configuration
  .configuration
    aiq-col(:span='12')
      .configuration-form
        .config-item
          label Title
          aiq-input(v-model="configurationStrings.title")
        .config-item
          label Description
          aiq-input(v-model="configurationStrings.description")
        .config-item
          label Name
          aiq-switch.margin-left-10(v-model="parseConfiguration.input_fields.name.show")
          .sub-label-input(v-if="parseConfiguration.input_fields.name.show")
            label Label
            aiq-input(v-model="configurationStrings.input_fields.name.label")
            label Place Holder
            aiq-input(v-model="configurationStrings.input_fields.name.placeholder")
        .config-item
          label Email
          aiq-switch.margin-left-10(v-model="parseConfiguration.input_fields.email.show")
          .sub-label-input(v-if="parseConfiguration.input_fields.email.show")
            label Label
            aiq-input(v-model="configurationStrings.input_fields.email.label")
            label Place Holder
            aiq-input(v-model="configurationStrings.input_fields.email.placeholder")
        .config-item
          aiq-col(:span='12')
            label Display Frequency
          aiq-col(:span='12')
            aiq-select(v-model="localConfiguration.visible_policy.frequency")
              aiq-option(v-for="visibilityFrequency in frequencyOptions"
                          :key="visibilityFrequency",
                          :value="visibilityFrequency",
                          :label="$filters.toUpperFirst(visibilityFrequency)")
          .sub-label-input(v-if="localConfiguration.visible_policy.frequency === 'custom'")
            label Time in minutes
            aiq-input(v-model.number="localConfiguration.visible_policy.custom_period_in_minutes"
                      type="number")
        .config-item
          aiq-col(:span='12')
            label Prechat Image
            aiq-tooltip(placement="top"
                        :content="$t('settings_tab.channels_tab.webchat_ui.prechat.image_upload.tooltip')")
              fa-icon.margin-left-10(:icon="['fas', 'circle-question']")
          aiq-col.image-uploader(:span='24')
            aiq-upload(accept="image/*"
                       name="file"
                       action="url"
                       :http-request="onImageUpload"
                       :show-file-list="false"
                       :on-error="onImageUploadFailure"
                       :on-success="onImageUploadSuccess")
              aiq-button(type="primary" :disabled="isPicUploading") Upload
            aiq-button.margin-left-10(v-if="parseConfiguration.picture"
                                      :disabled="isPicUploading"
                                      type="danger"
                                      @click="deleteImage") Delete
      
    aiq-col(:span='12')
      .configuration-simulation
        prechat-component(:payload="configurationPreview")

  .configuration-submit
    .float-right
      aiq-button(:disabled="pristine"
                @click="resetForm") Cancel
      aiq-button.save__button(type="primary"
                              :disabled="pristine"
                              @click="saveForm") Save
</template>

<script>
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import unset from 'lodash/unset';
import { mapState } from 'vuex';
import { IMAGE_ACL_TYPES } from '@/constants';

export default {
  name: 'prechat-configuration',
  data() {
    return {
      original: null,
      localConfiguration: {
        name: 'prechat_survey',
        content: '{ "input_fields": {} }',
        visible_policy: {
          frequency: 'once',
          custom_period_in_minutes: 0,
        },
      },
      parseConfiguration: {
        title: '',
        description: '',
        input_fields: {
          name: {
            show: false,
            is_required: false,
            placeholder: 'Name',
            label: 'Name:',
          },
          email: {
            show: false,
            is_required: false,
            placeholder: 'Email',
            label: 'Email:',
          },
        },
      },
      configurationStrings: {
        title: '',
        description: '',
        input_fields: {
          name: {
            placeholder: 'Name',
            label: 'Name:',
          },
          email: {
            placeholder: 'Email',
            label: 'Email:',
          },
        },
      },
      isPicUploading: false,
    };
  },
  watch: {
    webchatLanguageStrings: {
      handler(newVal) {
        this.configurationStrings = cloneDeep(get(newVal, 'prechat', {}));
      },
      deep: true,
    },
    parseConfiguration: {
      handler(newVal) {
        this.localConfiguration = { ...this.localConfiguration, content: JSON.stringify(newVal) };
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      webchatLanguageStrings: state => state.configs.language_strings,
    }),
    pristine() {
      return isEqual(this.original, this.localConfiguration) &&
      isEqual(this.configurationStrings, get(this.webchatLanguageStrings, 'prechat', {}));
    },
    frequencyOptions() {
      return ['once', 'custom'];
    },
    configurationPreview() {
      const content = JSON.parse(get(this.localConfiguration, 'content', ''));
      merge(content, this.configurationStrings);
      return { ...this.localConfiguration, content: JSON.stringify(content) };
    },
  },
  async mounted() {
    this.original = await this.$store.dispatch('settings/getSetting', { name: 'prechat_survey' });
    this.resetForm();
  },
  methods: {
    async onImageUpload(data) {
      this.isPicUploading = true;
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('acl', IMAGE_ACL_TYPES.PUBLIC);
      const payload = await this.$store.dispatch('configs/upload', formData);
      return payload;
    },
    onImageUploadFailure() {
      this.isPicUploading = false;
      this.$aiq.notify.error(this.$t('settings_tab.channels_tab.webchat_ui.prechat.image_upload.error'));
    },
    async onImageUploadSuccess(responsePayload) {
      this.isPicUploading = false;
      set(this.parseConfiguration, 'picture', responsePayload);
    },
    deleteImage() {
      unset(this.parseConfiguration, 'picture');
    },
    resetForm() {
      this.localConfiguration = cloneDeep(this.original);
      this.parseConfiguration = JSON.parse(get(this.localConfiguration, 'content', ''));
      this.configurationStrings = merge(this.configurationStrings, cloneDeep(get(this.webchatLanguageStrings, 'prechat', {})));
    },
    async saveForm() {
      try {
        const [original] = await
        Promise.all([
          this.$store.dispatch('settings/updateSetting', { name: 'prechat_survey', payload: this.localConfiguration }),
          this.$store.dispatch('configs/setWebchatLanguageStrings', { prechat: this.configurationStrings })]);
        this.original = original;
        this.$aiq.notify.success('Saved');
      } catch (err) {
        this.$aiq.notify.error(`Failed: ${err.message}`);
      }
    },
  },
};
</script>

<style>

.configuration-simulation {
  height: 480px;
  width: 320px;
  border: 1px solid;
  margin: auto;
}

.config-item {
  margin-top: 10px;
}

.configuration-form {
  padding: 10px;
}

.sub-label-input {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
}

.margin-left-10 {
  margin-left: 10px;
}

.float-right {
  margin: 10px;
  float: right;
}

.image-uploader {
  display: flex;
  margin-top: 10px;
}
</style>
