import Favico from 'favico.js';
import defaultIcon from '../assets/agentiq-icon-192.png';
import defaultAudio from '../assets/new_message.mp3';
import { getCustomerName, getCustomerFirstName } from '@/libs';
import { NOTIFICATION as _NOTIFICATION } from './pushNotification';
import { initialize } from '@/i18n';

let localFavicon = null;

if (Favico) {
  localFavicon = new Favico({ animation: 'popFade' });
}

export const favicon = localFavicon;

// moved to pushNotification to resolve dependencies.
export const NOTIFICATION = _NOTIFICATION;

export const requestNotificationPermission = () => new Promise((resolve, reject) => {
  if (!window.Notification) {
    reject(new Error('browserNotSupported'));
  } else {
    Notification.requestPermission().then(result => {
      result === 'granted' ? resolve(result) : reject(result);
    }, error => reject(error));
  }
});

// eslint-disable-next-line max-len
export const notify = (title, body, tag, clickToClear, icon = defaultIcon) => new Promise((resolve, reject) => {
  requestNotificationPermission().then(() => {
    resolve(new Notification(title, {
      body,
      icon,
      tag,
      requireInteraction: clickToClear,
    }));
  }, error => {
    reject(error);
  });
});

export const audioNotify = () => {
  new Audio(defaultAudio).play();
};

export const dispatchBrowserNotification = async(dispatch, message,
  { notificationMode, notificationEvent, notificationIncludeAudio, notificationClickToClear }) => {
  let tag = '';
  let title = '';
  let body = '';
  const isMessageEvent = (notificationEvent === NOTIFICATION.EVENT_MESSAGE);
  const lang = await initialize(); // should return i18n if initialized

  const customerLocalized = lang.t('notification.common.blank_customer');

  if (notificationMode === NOTIFICATION.MODE_GROUP_BY_TYPE) {
    tag = isMessageEvent ? NOTIFICATION.GROUP_MESSAGE : NOTIFICATION.GROUP_ASSIGNMENT;
    title = isMessageEvent ?
      NOTIFICATION.GENERIC_MESSAGE_TITLE.replace('Customer', customerLocalized) : NOTIFICATION.GENERIC_ASSIGNMENT_TITLE.replace('Customer', customerLocalized);
    body = NOTIFICATION.GENERIC_BODY;
  } else {
    if (notificationMode === NOTIFICATION.MODE_GROUP_BY_CUST) {
      tag = `${message.conversation.customer_id}${(isMessageEvent ? NOTIFICATION.GROUP_MESSAGE : NOTIFICATION.GROUP_ASSIGNMENT)}`;
    } else { // MODE_GROUP_BY_NONE
      tag = '';
    }
    const fullName = getCustomerName(message.conversation.customer);
    const firstName = getCustomerFirstName(message.conversation.customer);
    title = isMessageEvent ? NOTIFICATION.NAMED_MESSAGE_TITLE : NOTIFICATION.NAMED_ASSIGNMENT_TITLE;
    title = title.replace('{fullName}', fullName).replace('Customer', customerLocalized);
    body = NOTIFICATION.NAMED_BODY.replace('{firstName}', firstName).replace('Customer', customerLocalized.toLowerCase());
  }
  const room = message.rooms[0];
  dispatch('notifications/sendBrowserNotification', {
    title,
    body,
    tag,
    event: notificationEvent,
    agents: room.agents,
    message,
    conversation: message.conversation,
    includeAudio: notificationIncludeAudio,
    clickToClear: notificationClickToClear,
    isMessageEvent,
  }, { root: true });
};
