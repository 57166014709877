<template lang="pug">
.api-management
  .api-config__content(v-if="creatingDomain")
    domain-config(v-bind.sync="tempDomain"
                  :emitter="eventEmitter"
                  :expanded="true"
                  :is-pristine="false"
                  @cancel="cancel('Domain')"
                  @save="create('Domain')")
  .content-inner(v-else-if="!selectedDomain")
    .header
      h1.title APIs
    .api-cards
      api-card(v-for="domain in domains"
              :item="domain"
              :key="domain.id"
              @delete="deleteCard(domain.id)"
              @export="onExportDomain(domain)"
              @select="selectCard")
      .api-card--add(@click="addNewDomain")
        i.el-icon-plus
        aiq-upload.import(ref="upload"
                  accept=".json"
                  :action="'file'"
                  :auto-upload="false"
                  :on-change="importFile"
                  :show-file-list="false")
          aiq-button(size="small") Import

  .api-config(v-else)
    select-panel(back-button
                :items="selectedDomain.children"
                :new-name="newEndpointName"
                :selectedId="selectedEndpoint && selectedEndpoint.id"
                :title="`${selectedDomain.name} Endpoints`"
                @back="clearSelected"
                @create="addNewEndpoint"
                @delete="removeEndpoint"
                @load="onLoad"
                @select="selectEndpoint")
    .api-config__content
      domain-config(v-model="tempDomain"
                    :is-pristine="isDomainPristine"
                    :emitter="eventEmitter"
                    @cancel="cancel('Domain')"
                    @save="save('Domain')")
      endpoint-config(v-if="creatingEndpoint"
                      v-model="tempEndpoint"
                      :emitter="eventEmitter"
                      :is-pristine="isEndpointPristine"
                      :test-result="testResult"
                      :parent-url="parentUrl"
                      :parent-headers="parentHeaders"
                      @test="testApi"
                      @clean="testResult = null"
                      @cancel="cancel('Endpoint')"
                      @save="create('Endpoint')")
      endpoint-config(v-else-if="selectedEndpoint"
                      v-model="tempEndpoint"
                      :test-result="testResult"
                      :parent-url="parentUrl"
                      :parent-headers="parentHeaders"
                      :emitter="eventEmitter"
                      :is-pristine="isEndpointPristine"
                      @test="testApi"
                      @clean="testResult = null"
                      @cancel="cancel('Endpoint')"
                      @save="save('Endpoint')")
      content-empty(v-else)
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import mitt from 'mitt';

import { mapActions, mapGetters, mapState } from 'vuex';
import { SelectPanel, ContentEmpty } from '@/components';
import ApiCard from './ApiCard/ApiCard.vue';
import DomainConfig from './DomainConfig/DomainConfig.vue';
import EndpointConfig from './EndpointConfig/EndpointConfig.vue';
import { exportJsonFile } from '@/libs/fileUtils';

// Todo (Gabe) Set up unsaved changes warning
export default {
  name: 'apiManagement',
  components: {
    ApiCard,
    ContentEmpty,
    DomainConfig,
    EndpointConfig,
    SelectPanel,
  },
  computed: {
    ...mapState({
      domains: state => state.settings.domains,
    }),
    ...mapGetters({
      domainModel: 'settings/domainModel',
      endpointModel: 'settings/endpointModel',
      endpointsByDomain: 'settings/endpointsByDomain',
      selectedDomainEndpoints: 'settings/selectedDomainEndpoints',
      selectedDomain: 'settings/selectedDomain',
      selectedEndpoint: 'settings/selectedEndpoint',
    }),
    isDomainPristine() {
      return isEqual(this.selectedDomain, this.tempDomain);
    },
    isEndpointPristine() {
      return isEqual(this.selectedEndpoint, this.tempEndpoint);
    },
    isPristine() {
      // TODO (Gabe) - to be used for unsaved changes warning
      return this.isDomainPristine && this.isEndpointPristine;
    },
    parentUrl() {
      const protocol = get(this.tempDomain, 'protocol', 'http');
      const host = get(this.tempDomain, 'host', '');
      return `${protocol}://${host}`;
    },
    parentHeaders() {
      return get(this.tempDomain, 'headers', {});
    },
  },
  data() {
    return {
      /**
       * Created Event Emitter to handle case of canceling changes. DomainConfig has
       * two representations of the header pairs. One in object and one in a 2-d array.
       * A separate representation is needed because empty fields cannot be translated
       * back to object. DomainConfig.vue needs a way to know of the cancel event.
       */
      eventEmitter: mitt(),
      creatingDomain: false,
      creatingEndpoint: false,
      newEndpointName: '',
      tempDomain: {},
      tempEndpoint: {},
      testResult: null,
    };
  },
  created() {
    this.getAPISettings();
  },
  mounted() {
    this.tempEndpoint = { ...this.selectedEndpoint };
  },
  watch: {
    selectedEndpoint: {
      handler(endpoint) {
        this.tempEndpoint = { ...endpoint };
      },
      deep: true,
    },
    selectedDomain: {
      handler(domain) {
        this.tempDomain = { ...domain };
        this.removeEndpointForm();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      createDomain: 'settings/createDomain',
      createEndpoint: 'settings/createEndpoint',
      deleteDomain: 'settings/deleteDomain',
      deleteEndpoint: 'settings/deleteEndpoint',
      getAPISettings: 'settings/getAPISettings',
      selectDomain: 'settings/selectDomain',
      selectEndpoint: 'settings/selectEndpoint',
      updateDomain: 'settings/updateDomain',
      updateEndpoint: 'settings/updateEndpoint',
      testEndpoint: 'settings/testEndpoint',
      exportDomain: 'settings/exportDomain',
      importDomain: 'settings/importDomain',
    }),
    addNewDomain(ev) {
      if (ev.target.classList.contains('api-card--add') || ev.target.classList.contains('el-icon-plus')) {
        this.tempDomain = cloneDeep(this.domainModel);
        this.creatingDomain = true;
        return;
      }
    },
    addNewEndpoint(name) {
      this.tempEndpoint = this.endpointModel(name);
      this.newEndpointName = name;
      this.creatingEndpoint = true;
    },
    cancel(type) {
      this.creatingDomain = false;
      this.removeEndpointForm();
      this[`temp${type}`] = cloneDeep(this[`selected${type}`]);
      this.eventEmitter.emit(`cancel${type}`);
    },
    removeEndpointForm() {
      this.creatingDomain = false;
      this.newEndpointName = '';
    },
    clearSelected() {
      this.selectDomain(null);
      this.selectEndpoint(null);
    },
    create(type) {
      const params = this[`temp${type}`];

      return this[`create${type}`](params).then(item => {
        this[`creating${type}`] = false;
        this.$aiq.notify.success(`Successfully created ${type}!`);

        return item;
      }).catch(() => {
        this.$aiq.notify.error('Problem with creating.');
      }).then(item => {
        this[`select${type}`](item.id);
      });
    },
    deleteCard(id) {
      const card = this.endpointsByDomain?.find(d => d.id === id);

      if (card?.children?.length) {
        // todo (Gabe) set up alerts from ElementUI (this.$aiq.alert)
        const title = 'Notice';
        const message = 'Please delete endpoints before deleting domain.';
        return this.$aiq.confirm(title, message);
      }

      this.deleteDomain(id).then(() => {
        this.$aiq.notify.info('Successfully deleted domain.');
      }).catch(() => {
        this.$aiq.notify.error('Unable to delete domain.');
      });
    },
    removeEndpoint(id) {
      this.deleteEndpoint(id).then(() => {
        this.$aiq.notify.success('Successfully deleted endpoint.');
      }).catch(() => {
        this.$aiq.notify.error('Unable to delete endpoint.');
      });
    },
    onLoad($state) {
      // TODO (Gabe) - pagination
      $state.complete();
    },
    save(type) {
      this[`update${type}`](this[`temp${type}`]).then(() => {
        this.$aiq.notify.success(`${type} saved.`);
      }).catch(() => {
        this.$aiq.notify.error('Problem with creating.');
      });
    },
    testApi({ endpoint, payload }) {
      this.testEndpoint({ endpoint, payload, webhook_id: get(this.tempDomain, 'id') })
        .then(body => {
          this.testResult = body;
        }).catch(err => {
          this.$aiq.notify.error(`Error: ${err.message}`);
        });
    },
    selectCard(id) {
      this.selectDomain(id);
    },
    async onExportDomain(domain) {
      if (domain) {
        const exportedData = await this.exportDomain(domain.name);
        exportJsonFile(exportedData, `${domain.name}.json`);
      }
    },

    async importFile(data) {
      try {
        const importedData = JSON.parse(await data.raw.text());
        await this.importDomain(importedData);
        this.$aiq.notify.success('Imported');
        await this.getAPISettings();
      } catch (err) {
        this.$aiq.notify.error(`Error: ${err.message}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/aiq-extensions.scss";
@import "../../../styles/aiq-mixins.scss";

.api-management {
  flex: 2;
}

.content-inner {
  @extend %settings-content-inner;

  .api-card--add {
    @extend %api-card;

    align-items: center;
    justify-content: center;

    .el-icon-plus:before {
      color: #cbcfd6;
      font-size: 80px;
    }

    .import {
      position: absolute;
      margin-left: 180px;
      margin-top: 210px;
    }
  }
  .api-cards {
    display: flex;
    flex-wrap: wrap;
    margin: -12px;
  }
}

.api-config {
  display: flex;
  align-items: stretch;

  .api-config__content {

    @include space-between-v(4px);

    display: flex;
    flex-basis: 1px;
    flex-direction: column;
    flex-grow: 2;
  }
}
</style>
