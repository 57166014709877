<template lang="pug">
.document-sign(v-loading="true")
  .successful(v-if="authenticated") Connected
</template>

<script>
import * as log from 'loglevel';
import get from 'lodash/get';
import pick from 'lodash/pick';
import OAuth from '@/libs/mixins/OAuth';
import { randomString } from '@/libs/stringUtils';

const TAG = '[DocumentSignAuthentication]';

export default {
  name: 'DocumentSignAuthentication',
  mixins: [OAuth],
  data() {
    return {
      authenticated: false
    };
  },
  props: {
    integration: {
      type: Object,
      default: '',
    },
    config: null,
  },
  emits: ['authenticated', 'failure'],
  async mounted() {
    try {
      // Should not be mounted without integration and config value
      if (!this.integration || !this.config) {
        throw new Error(`Invalid values: ${this.integration}, config: ${this.config}`);
      }

      // Check if we access token
      if (await this.$store.dispatch('integrations/checkDocumentIntegrationConnection', this.integration)) {
        return this.$emit('authenticated');
      }

      // authentication
      await this.onStartAuth(this.integration);
      this.authenticated = true;

      this.$emit('authenticated');
    } catch (err) {
      this.$aiq.notify.error(err.message);
      log.error(err);
      this.$emit('failure', err);
    }
  },
  methods: {
    async onStartAuth(name) {
      // Start Authentication
      const state = randomString();
      let code_verifier;
      let params = null;

      log.debug(TAG, 'oauth config:', this.config);

      // NOTE: The logic is only for authorization with PCKE.
      if (get(this.config, 'use_pkce', true)) {
        code_verifier = this.createCodeVerifier();
        params = {
          state,
          code_challenge: this.createCodeChallenge(code_verifier),
          ...pick(this.config, ['response_type', 'client_id', 'scope', 'redirect_uri', 'code_challenge_method']),
        };
      } else {
        params = { state, ...pick(this.config, ['response_type', 'client_id', 'scope', 'redirect_uri']) };
      }

      const url = this.oauth2Url(get(this.config, 'authorization_endpoint', ''), params);
      log.debug(TAG, 'oauth url:', url, params);

      const code = await this.openOauth2Popup(url, this.integration);
      log.debug(TAG, 'authorization code:', code);

      // exchange with access token
      return this.$store.dispatch('integrations/tokenExchange', { name, query: { code, state, code_verifier } });
    }
  },
};
</script>