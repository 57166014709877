const _ = require('lodash');

export const makeDependencyAlertMsg = (parentName, res, ctx) => {
  const data = res.data;
  const suffix = ctx.$t('iq_tools_tab.dependency_error');
  const list = [];
  const intents = _.get(data, 'contents.intents', []);
  const dialogs = _.get(data, 'contents.dialogs', []);
  const workflows = _.get(data, 'contents.workflows', []);

  if (intents.length){
    list.push(`${intents.length}. intents: ${getDependencyName(intents)}`);
  }

  if (dialogs.length){
    list.push(`${dialogs.length} ${list.length + 1}. dialogs: ${getDependencyName(dialogs)}`);
  }

  if (workflows.length){
    list.push(`${workflows.length} workflows: ${getDependencyName(workflows)}`);
  }

  // construct the list of dependency items
  const contents = list.map(item => `<li>${item}</li>`).join('');

  // construct the full content
  const content = `
    <div>
      <!-- message about the dependency -->
      <div>${ctx.$t('iq_tools_tab.nested_dependency_msg', {name: parentName.toLowerCase()})} </div>
      <!-- the list of dependency items -->
      <ul class='dependency-items-list'>${contents}</ul>
      <div>${suffix}</div>
    </div>
  `;

  return {
    title: `Delete ${parentName}`,
    content,
  };
};

const getDependencyName = (data)=>{
  return data.map(item=> `<b>${item.name}</b>`).join(', ');
};

export const changeEntityTypeWarning = {
  title: 'Change Entity Type?',
  content: 'Changing the entity type can significantly change the behavior of the system. Are you sure you want to proceed?',
};
