<template lang="pug">
  side-bar-vue(:baseResource="baseResource" :routeChildren="routeChildren")
</template>

<script>
import SideBarVue from '@/components/layouts/SideBarVue.vue';
import SettingRoutes from './Settings.router';

export default {
  components: {
    SideBarVue,
  },
  data() {
    return {
      baseResource: SettingRoutes.path,
      routeChildren: SettingRoutes.children
    };
  },
};
</script>

<style lang="scss">
  .content-inner {
    .el-tabs--border-card {
      border-bottom-width: 0px;
      box-shadow: none;
    }
  }
</style>
