import PerfectScrollbar from 'perfect-scrollbar';
import cloneDeep from 'lodash/cloneDeep';
import { createNamespacedHelpers } from 'vuex';
import get from 'lodash/get';

import actionItem from '../../ActionItem/ActionItem.vue';
import actionInput from './ActionInput.vue';
import aiqCommandBuilder from './Commands/AIQCommandBuilder/AIQCommandBuilder.vue';
import {
  defaultBuildingKitTabs,
  getInputKeyupSubject,
  unicodeToASCII,
} from '@/libs';
import {
  AGENT_LIMIT,
  TEAM_LIMIT,
  TAG_LIMIT,
  CATEGORY_LIMIT,
  ASSETS_LIMIT,
  DOCS_LIMIT,
} from '@/constants/pagination';

const LIMIT = 70;
const SEARCH_TIMEOUT_MS = 1000;
const { mapActions, mapState, mapGetters } = createNamespacedHelpers('buildingKit');

const sanitize = response => {
  const sanitized = cloneDeep(response);
  const text = get(response, 'payload.message.payload.content');

  if (text) {
    const sanitizedText = unicodeToASCII(get(response, 'payload.message.payload.content', ''));
    sanitized.payload.message.payload.content = sanitizedText;
  }

  return sanitized;
};
export default {
  name: 'buildingKit',
  components: {
    // actionCards,
    actionItem,
    actionInput,
    aiqCommandBuilder,
  },
  data() {
    return {
      searchQuery: '',
      activeTab: '',
      type: 'all',
      showNewForm: false,
      showNewResponseInput: false,
      searchSubscription: null,
      // TODO (Gabe) keep this high limit until fixing building kit initial load
      limit: LIMIT,
      offset: 0,
      ps: null,
    };
  },
  created() {
    this.getDBKAll();
  },
  mounted() {
    const elem = this.$refs.search.$el;
    this.searchSubscription = getInputKeyupSubject(null, SEARCH_TIMEOUT_MS, elem)
      .subscribe(() => {
        this.offset = 0;
        this.getDBKAll({ q: this.searchQuery });
      });

    Promise.all([
      this.$store.dispatch('agents/getAgentsList', [{ limit: AGENT_LIMIT }]),
      this.$store.dispatch('tags/getTagsList', [{ limit: TAG_LIMIT }]),
      this.$store.dispatch('categories/getCategoriesList', [{ limit: CATEGORY_LIMIT }]),
      this.$store.dispatch('teams/getTeamsList', [{ limit: TEAM_LIMIT }]),
      this.$store.dispatch('assets/getAssetsList', [{ limit: ASSETS_LIMIT }]),
      this.$store.dispatch('documents/getDocumentsList', [{ limit: DOCS_LIMIT }]),
    ]).then(() => this.getFunctions(this.$store))
      .then(() => {
        const scrollWrapperElem = this.$refs.scrollWrapper;
        if (!scrollWrapperElem) {
          return;
        }
        this.ps = new PerfectScrollbar(scrollWrapperElem);
      });

    this.activeTab = this.type;
  },
  beforeUnmount() {
    if (this.ps) {
      this.ps.destroy();
      this.ps = null;
    }
    this.searchSubscription && this.searchSubscription.unsubscribe();
  },
  computed: {
    ...mapState({
      dataLoading: 'loading',
      dataLoaded: 'loaded',
    }),
    ...mapGetters(['dataByType']),
    allItemsLoaded() {
      return this.offset >= this.totalDataItems;
    },
    dataForType() {
      return this.dataByType[this.type];
    },
    paginatedData() {
      return this.dataForType ? this.dataForType.slice(0, this.offset) : [];
    },
    totalDataItems() {
      return this.dataForType.length;
    },
    newCommandIconName() {
      return this.showNewForm ? 'el-icon-minus' : 'el-icon-plus';
    },
    newResponseIconName() {
      return this.showNewResponseInput ? 'el-icon-minus' : 'el-icon-plus';
    },
  },
  methods: {
    ...mapActions([
      'getFunctions',
      'getDBKAll',
    ]),
    changeTab(tab) {
      this.type = tab.paneName;
    },
    createResponse(response) {
      this.$store.dispatch('buildingKit/createAction', sanitize(response))
        .then(() => {
          this.$aiq.notify.success('New response has been added.');
        });
    },
    updateResponse(id, response) {
      this.$store.dispatch('buildingKit/updateAction', [id, sanitize(response)])
        .then(() => {
          this.$aiq.notify.success('Response has been updated.');
        });
    },
    deleteResponse(id) {
      this.$aiq.confirm(
        'Delete Response',
        'Do you really want to delete this response?',
      ).then(
        () => {
          this.$store.dispatch('buildingKit/deleteAction', id)
            .then(() => {
              this.$aiq.notify.info('Response has been deleted.');
            });
        },
        () => { },
      );
    },
    focusOnRespondInput() {
      this.$refs.search.$el.focus();
    },
    onScrollLoad($state) {
      if (this.allItemsLoaded) {
        $state.complete();
      } else {
        $state.loaded();
        const fetchedItems = this.dataForType.slice(this.offset, this.offset + this.limit);
        this.paginatedData.push(...fetchedItems);
        this.offset += fetchedItems.length;
      }
    },
    scrollToTop() {
      const container = this.$refs.scrollWrapper;

      container.scrollTop = 0;
      if (this.ps) {
        this.ps.update();
      }
    },
    toggleNewForm() {
      this.showNewForm = !this.showNewForm;
    },
    toggleNewResponseInput() {
      this.showNewResponseInput = !this.showNewResponseInput;
    }
  },
  props: {
    focused: Boolean,
    tabs: {
      type: Array,
      default() {
        return defaultBuildingKitTabs;
      },
    },
  },
  watch: {
    dataForType: {
      deep: true,
      handler() {
        this.scrollToTop();
      },
    },
    focused: {
      handler(newFocused) {
        if (newFocused) this.focusOnRespondInput();
      },
    },
    type: {
      handler(__newType, oldType) {
        if (!oldType) {
          this.offset = 0;
        } else {
          this.offset = this.limit;
        }
    
        if (this.searchQuery) {
          this.searchQuery = '';
          this.getDBKAll();
        }
    
        this.$refs.search.$el.focus();
        this.scrollToTop();
      },
    },
  }
};
