<template lang="pug">
  route-with-sidebar(:baseResource="baseResource", :bars="viewableBars")
</template>

<script>
import pick from 'lodash/pick';
import RouteWithSidebar from '@/components/layouts/routeWithSidebar.vue';

export default {
  components: {
    RouteWithSidebar,
  },
  data() {
    return {
      viewableBars: [],
    };
  },
  watch: {
    $route(to) {
      this.onRouteChange(to);
    },
  },
  props: {
    baseResource: {
      type: String,
      default: '',
    },
    routeChildren: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.onRouteChange(this.$route);
  },
  methods:{
    onRouteChange(to) {
      const barMenus = this.routeChildren.map(child => pick(child, ['path', 'name', 'meta']));
      this.viewableBars = barMenus.filter(({ path }) => this.canView(`${this.baseResource}/${path}`));

      if (to.path === this.baseResource && this.viewableBars.length !== 0) {
        const path = `${this.baseResource}/${this.viewableBars[0].path}`;
        this.$router.push(path);
      }
    }
  }
};

</script>