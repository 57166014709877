<template lang="pug">
.queue-configuration
  .form-container
    .sub-header--solid {{$t('settings_tab.channels_tab.webchat_ui.queue_configuration')}}
    .form__inner-content
      .settings-container
        .header-config-container
          label {{ $t(`${baseTranslationPath}.title`) }}

      .settings-container
        aiq-form
          aiq-form-item
            label.small-font {{ $t(`${baseTranslationPath}.targets`) }}
              aiq-tooltip(placement="top")
                template(v-slot:content)
                  div(v-html="$t(`${baseTranslationPath}.targets_tooltip`)")
                fa-icon.question-mark(:icon="['fas', 'circle-question']")
            aiq-select(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.visibility" multiple placeholder="Select")
              aiq-option(v-for="item in channelOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value")

          aiq-form-item(v-if="showQueueMessageField")
            label.small-font {{ $t(`${baseTranslationPath}.define_queue_message`) }}
              aiq-tooltip(placement="top")
                template(v-slot:content)
                  div(v-html="$t(`${baseTranslationPath}.define_queue_message_tooltip`)")
                fa-icon.question-mark(:icon="['fas', 'circle-question']")
            aiq-input(v-model="localWebchatConfigsStrings.features.show_queue_transparency.display_message" placeholder="Write message" size="small")
          
          aiq-form-item
            label.inline-item.small-font {{ $t(`${baseTranslationPath}.segment_priority_queue`) }}
              aiq-tooltip(placement="top")
                template(v-slot:content)
                  div(v-html="$t(`${baseTranslationPath}.segment_priority_queue_tooltip`)")
                fa-icon.question-mark(:icon="['fas', 'circle-question']")
            aiq-switch(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.segment_queue"
                        active-color="#30C28B"
                        inactive-color="#C0CCDA")
          
          aiq-form-item
            label.inline-item.small-font {{ $t(`${baseTranslationPath}.queue_hours`) }}
              aiq-tooltip(placement="top")
                template(v-slot:content)
                  div(v-html="$t(`${baseTranslationPath}.queue_hours_tooltip`)")
                fa-icon.question-mark(:icon="['fas', 'circle-question']")
            aiq-switch(:model-value="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.queue_hours"
                        active-color="#30C28B"
                        inactive-color="#C0CCDA"
                        @change="queueHoursChanged")

          aiq-form-item(v-if="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.queue_hours")
            aiq-collapse.queue-hours-config(v-model="activeDays")
              aiq-collapse-item(v-for="day in daysOfWeek"
                                :name="day.key"
                                :key="day.key"
                                :class="{'is-error': hoursPerDayIsInvalid(day.key) }")
                template(v-slot:title)
                  .left-title
                    aiq-switch(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].is_open"
                            active-color="#30C28B"
                            inactive-color="#C0CCDA")
                    span {{ day.label }}
                  .middle-title(v-if="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].is_open && !activeDays.includes(day.key)")
                    | {{ formatDate(localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].open_time) }} - {{ formatDate(localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].close_time) }}
                  
                aiq-form-item.queue-hours-config-field(:class="{'is-error': v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].open_time.$error }")
                  label.sub-label.small-font {{ $t(`${baseTranslationPath}.open_time`) }} 
                    span.field-label ({{ $t(`${baseTranslationPath}.display_in`, { time_zone: tzAbbr, defaultValue: `Time appears in ${tzAbbr}`}) }})
                  aiq-time-picker.el-input__inner(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].open_time"
                                                  ref="open_time"
                                                  :placeholder="$t(`${baseTranslationPath}.time_format`)",
                                                  name="hours_per_day.open_time"
                                                  format="h:mm A")
                  span.text-danger(v-show="v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].open_time.$error") 
                    | {{  v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].open_time.$errors[0]?.$message }}
                aiq-form-item.queue-hours-config-field(:class="{'is-error': v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].close_time.$error }")
                  label.sub-label.small-font {{ $t(`${baseTranslationPath}.close_time`) }} 
                    span.field-label ({{ $t(`${baseTranslationPath}.display_in`, { time_zone: tzAbbr, defaultValue: `Time appears in ${tzAbbr}`}) }})
                  aiq-time-picker.el-input__inner(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].close_time"
                                                  ref="close_time"
                                                  :placeholder="$t(`${baseTranslationPath}.time_format`)",
                                                  name="hours_per_day.close_time"
                                                  format="h:mm A")
                  span.text-danger(v-show="v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].close_time.$error") 
                    | {{  v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].close_time.$errors[0]?.$message }}
            span.text-danger(v-show="v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day.$error && v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day.shouldBeOpenAtLeast1Day.$invalid") 
              | {{  v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day.$errors[0]?.$message }}
          
          aiq-form-item
            label.inline-item.small-font {{ $t(`${baseTranslationPath}.leave_queue`) }}
              aiq-tooltip(placement="top")
                template(v-slot:content)
                  div(v-html="$t(`${baseTranslationPath}.leave_queue_tooltip`)")
                fa-icon.question-mark(:icon="['fas', 'circle-question']")
            aiq-switch(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue"
                        active-color="#30C28B"
                        inactive-color="#C0CCDA")
          
          aiq-form-item(v-if="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue", :class="{'is-error': v$.localWebchatConfigsStrings.features.show_queue_transparency.leave_queue_prompt.$error }")
            label.small-font {{ $t(`${baseTranslationPath}.leave_queue_prompt`) }}
            aiq-input(v-model="localWebchatConfigsStrings.features.show_queue_transparency.leave_queue_prompt"
                      size="small"
                      name="queueTransparency.leave_queue_prompt")
            span.text-danger(v-if="v$.localWebchatConfigsStrings.features.show_queue_transparency.leave_queue_prompt.$error") {{ v$.localWebchatConfigsStrings.features.show_queue_transparency.leave_queue_prompt.$errors[0]?.$message }}
          
          aiq-form-item(v-if="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue", :class="{'is-error': v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue_workflow.$error}")
            label.small-font {{ $t(`${baseTranslationPath}.leave_queue_workflow`) }}
              aiq-tooltip(placement="top")
                template(v-slot:content)
                  div(v-html="$t(`${baseTranslationPath}.leave_queue_workflow_tooltip`)")
                fa-icon.question-mark(:icon="['fas', 'circle-question']")
            aiq-select(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue_workflow" 
                      :placeholder="$t('global.select')" 
                      name="queueTransparency.leave_queue_workflow")
              aiq-option(v-for="item in workflows"
                        :key="item"
                        :label="item"
                        :value="item")
            span.text-danger(v-if="v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue_workflow.$error") {{ v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue_workflow.$errors[0]?.$message }}

      .settings-container
        .settings__item
          .save-buttons__container.settings__item
            aiq-button(:disabled="isQueueConfigChanged"
                      @click="resetConfig") {{$t('global.cancel')}}
            aiq-button.save__button(type="primary"
                                    :disabled="isQueueConfigChanged"
                                    @click="saveConfig") {{$t('global.save')}}
</template>

<script>
import difference from 'lodash/difference';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import { mapState } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { helpers, requiredIf, maxValue, minValue } from '@vuelidate/validators';
import { HAMBURGER_MENU_SELECTION_OPTIONS } from '@/constants/settings';
import { useTimeZone, getTimeZoneAbbr } from '@/libs';

const BASE_TRANSLATION_PATH = 'settings_tab.channels_tab.webchat_configuration.queue_transparency';

const shouldBeOpenAtLeast1Day = (value) => {
  return Object.values(value).some(day => day.is_open);
};

export default {
  name: 'QueueConfiguration',
  props: {
    webchatConfigs: {
      type: Object,
    },
    workflows: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['save'],
  setup () {
    return { v$: useVuelidate() };
  },
  validations () {
    return {
      localWebchatConfigs: {
        features: {
          SHOW_QUEUE_TRANSPARENCY: {
            visibility: { $autoDirty: true },
            display_message: { $autoDirty: true },
            segment_queue: { $autoDirty: true },
            leave_queue: { $autoDirty: true },
            leave_queue_prompt: { $autoDirty: true },
            leave_queue_workflow:{ 
              requiredIf: requiredIf(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue), 
              $autoDirty: true 
            },
            queue_hours: { $autoDirty: true },
            queue_hours_timezone: {
              requiredIf: requiredIf(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.queue_hours), 
              $autoDirty: true 
            },
            hours_per_day: {
              ...(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.queue_hours ?
                { 
                  shouldBeOpenAtLeast1Day: helpers.withMessage(this.$t(`${this.baseTranslationPath}.must_define_at_least_1_day`), shouldBeOpenAtLeast1Day),
                  ...this.daysOfWeek.reduce((acc, day) => (
                    {
                      ...acc, 
                      [day.key]: { 
                        is_open: { 
                          $autoDirty: true 
                        }, 
                        open_time: {
                          requiredIf: requiredIf(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].is_open),
                          ...(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].close_time ?
                            { maxValue: helpers.withMessage(this.$t(`${this.baseTranslationPath}.maximum_value_allowed`, { maxValue: moment(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].close_time).format('h:mm A') }), maxValue(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].close_time)) } : {}),
                          $autoDirty: true 
                        }, 
                        close_time: { 
                          requiredIf: requiredIf(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].is_open),
                          ...(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].open_time ?
                            { maxValue:  helpers.withMessage(this.$t(`${this.baseTranslationPath}.minimum_value_allowed`, { minValue: moment(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].open_time).format('h:mm A') }), minValue(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day[day.key].open_time)) } : {}),
                          $autoDirty: true 
                        } 
                      }
                    }), {}
                  ),
                } : {}),
              $autoDirty: true,
            },
          }
        }
      },
      localWebchatConfigsStrings: {
        features: {
          show_queue_transparency: {
            leave_queue_prompt: {
              requiredIf: requiredIf(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue), 
              $autoDirty: true 
            }
          }
        }
      }
    };
  },
  data() {
    return {
      activeDays: [],
      channelOptions: cloneDeep(HAMBURGER_MENU_SELECTION_OPTIONS),
      localWebchatConfigsStrings: {
        features: {
          show_queue_transparency: {
            display_message: '',
            leave_queue_prompt: '',
          },
        },
      },
      localWebchatConfigs: cloneDeep(this.webchatConfigs),
      tzAbbr: '',
    };
  },
  watch: {
    webchatLanguageStrings: {
      handler(newVal) {
        this.localWebchatConfigsStrings = cloneDeep(get(newVal, 'webchat', {}));
      },
      deep: true,
    },
    webchatConfigs(newVal) {
      this.localWebchatConfigs = this.mapDateTime(newVal, 'date');
    },
  },
  computed: {
    ...mapState({
      webchatLanguageStrings: state => state.configs.language_strings,
    }),
    baseTranslationPath() {
      return BASE_TRANSLATION_PATH;
    },
    daysOfWeek() {
      return [
        { key: 1, label: this.$t('week_days.monday.long_name') },
        { key: 2, label: this.$t('week_days.tuesday.long_name') },
        { key: 3, label: this.$t('week_days.wednesday.long_name') },
        { key: 4, label: this.$t('week_days.thursday.long_name') },
        { key: 5, label: this.$t('week_days.friday.long_name') },
        { key: 6, label: this.$t('week_days.saturday.long_name') },
        { key: 0, label: this.$t('week_days.sunday.long_name') },
      ];
    },
    currentTimeZone() {
      const timeZone = get(this.localWebchatConfigs, 'features.SHOW_QUEUE_TRANSPARENCY.queue_hours_timezone', moment.tz.guess());
      const abbreviation = moment.tz(timeZone).format('z');
      return abbreviation;
    },
    localWebchatConfigsPayload() {
      return this.mapDateTime(this.localWebchatConfigs);
    },
    isQueueConfigChanged() {
      return isEqual(this.localWebchatConfigsPayload.features.SHOW_QUEUE_TRANSPARENCY, this.webchatConfigs.features.SHOW_QUEUE_TRANSPARENCY) &&
          isEqual(this.localWebchatConfigsStrings.features.show_queue_transparency, get(this.webchatLanguageStrings, 'webchat.features.show_queue_transparency', {}));
    },
    showQueueMessageField() {
      return difference(this.channelOptions.map(x => x.value),
        this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.visibility).length !== 0;
    },
  },
  methods: {
    async saveConfig() {
      if (!(await this.v$.$validate())) {
        this.$aiq.notify.error(
          this.$t('settings_tab.channels_tab.webchat_configuration.unable_save_error'),
        );
        return;
      }
      this.$emit('save', {
        config: this.localWebchatConfigsPayload,
        configStrings: this.localWebchatConfigsStrings,
      });
    },
    formatDate(date){
      return date ? moment(date).format('h:mm A') : '';
    },
    mapDateTime(payload, to = 'time') {
      const webchatConfigs = cloneDeep(payload);
      const hoursPerDay = webchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day;
      for (const key in get(webchatConfigs, 'features.SHOW_QUEUE_TRANSPARENCY.hours_per_day', {})) {
        if (hoursPerDay[key].open_time) {
          hoursPerDay[key].open_time = to === 'time' ?
            moment(hoursPerDay[key].open_time).format('HH:mm:ss') :
            moment(`${moment().format('YYYY-MM-DD')} ${hoursPerDay[key].open_time}`).toDate();
        }
        if (hoursPerDay[key].close_time) {
          hoursPerDay[key].close_time = to === 'time' ?
            moment(hoursPerDay[key].close_time).format('HH:mm:ss') :
            moment(`${moment().format('YYYY-MM-DD')} ${hoursPerDay[key].close_time}`).toDate();
        }
      }
      return webchatConfigs;
    },
    hoursPerDayIsInvalid(key) {
      const hoursPerDay = this.v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day;
      return hoursPerDay.$error && hoursPerDay.shouldBeOpenAtLeast1Day.$invalid || (hoursPerDay[key].open_time.$error || hoursPerDay[key].close_time.$error);
    },
    resetConfig() {
      this.localWebchatConfigs = this.mapDateTime(this.webchatConfigs, 'date');
      this.localWebchatConfigsStrings = cloneDeep(get(this.webchatLanguageStrings, 'webchat', {}));
    },
    async queueHoursChanged(value) {
      if (isEmpty(get(this.localWebchatConfigs, 'features.SHOW_QUEUE_TRANSPARENCY.hours_per_day', {}))) {
        const hoursPerDay = this.daysOfWeek.reduce((acc, day) => ({...acc, [day.key]: { is_open: false, open_time: null, close_time: null }}), {});
        this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.hours_per_day = hoursPerDay;
      }
      this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.queue_hours_timezone = value ? await useTimeZone(
        Intl.DateTimeFormat().resolvedOptions().timeZone, 
        this.$store.dispatch,
        this.$store._state.data.configs
      ) : '';
      this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.queue_hours = value;
    },
    async getTzAbbr(){
      const timezone = await useTimeZone(
        Intl.DateTimeFormat().resolvedOptions().timeZone, 
        this.$store.dispatch,
        this.$store._state.data.configs
      );

      this.tzAbbr = getTimeZoneAbbr(timezone);
    }
  },
  async mounted() {
    await this.getTzAbbr();
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-variables.scss";

.form-container {
  flex: 1;
  margin-bottom: 20px;

  .form__inner-content {
    background: #FFFFFF;
    border: 1px solid #E0E6ED;
    display: flex;
    flex-direction: column;
    padding: 18px;

    * {
      margin: 5px 0px;
    }

    .settings-container {
      display: flex;

      .settings__item {
        align-self: flex-start;
        flex-grow: 1;
        min-width: 589px;
      }

      .el-form-item {
        &:not(.queue-hours-config-field) {
          margin-bottom: 15px;
        }

        &.mb-0 {
          margin-bottom: 0;
        }

        :deep(.el-form-item__content) {
          line-height: 15px !important;
          display: block;
          label {
            font-weight: 300;
          }
          span.text-danger {
            height: 0;
            margin-top: -5px;
            color: #F56C6C;
            font-size: 12px;
            display: block;
          }
          svg.question-mark {
            margin-left: 5px;
            margin-top: 0;
            margin-bottom: 0;
            &:hover {
              color: $aiq-bgc-header;
            }
            &:focus {
              outline: none;
            }
          }
          .inline-item {
            display: inline-flex;
            width: 300px !important;
          }
          .el-input__icon {
            position: unset;
            width: unset;
            margin-right: unset;
          }
          .el-input__wrapper {
            box-shadow: unset;
          }
        }

        .el-collapse.queue-hours-config {
          border: unset;
          .left-title {
            flex-basis: 122px;
            text-align: left;
            span {
              margin-left: 5px;
            }
          }
          :deep {
            .el-collapse-item {
              border: 1px solid rgb(197, 204, 210);
              margin: 8px 0 8px 0;
              &.is-error {
                border-color: #F56C6C;
              }
              .el-date-editor {
                width: 100%;
                margin-bottom: 5px;
              }
            }
            .el-collapse-item__header {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding-left: 5px;
              background-color: unset;
              border: unset;
              font-weight: 700;
            }
            .el-collapse-item__wrap {
              border: unset;
              background-color: transparent;
              .el-collapse-item__content {
                min-height: 135px;
                overflow-y: auto;
                height: inherit;
                padding: 0px 5px 0px 5px;
                label {
                  font-weight: 400;
                  line-height: 22px;
                  span {
                    font-weight: 300;
                  }
                }
              }
            }

          }
        }
      }
    }

    .save-buttons__container {
      background-color: #fff;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      left: 0;
      margin-bottom: 0px;
      padding: 5px 8px 0px 0px;
      width: calc(100% - 4px);

      * {
        margin: 0px 5px;
      }

      .save__button {
        margin-right: 0px;
      }
    }
  }
}
</style>
