import { CUSTOM_EVTS, IDS } from './conversationConstants';
import { KEYBOARD_KEYS } from '@/constants';

export default {
  custom: {
    // Note(Jaekwan): The below mapping is problematic and seems too critical functionalites
    // to bind with a single key. So remove it for now.
    // [KEYBOARD_KEYS.C_LOWER]: CUSTOM_EVTS.CLOSE,
    // [KEYBOARD_KEYS.L_LOWER]: CUSTOM_EVTS.LEAVE,
    [KEYBOARD_KEYS.S_LOWER]: CUSTOM_EVTS.STAR,
  },
  focus: {
    [KEYBOARD_KEYS.F_LOWER]: IDS.CONV_SEARCH,
    [KEYBOARD_KEYS.BACKSLASH]: IDS.CHAT_INPUT,
  },
  navigation: [IDS.CONV_SCROLL, IDS.MSGS_SCROLL],
};
