import axios from 'axios';
import moment from 'moment';
import { getUrl } from '@/config/api.routes';

const getMetrics = (type, { commit }, params, method) => axios[method](getUrl(`pulse.${type}`), params)
  .then(data => {
    const metrics = data.data || {};
    commit('GET_METRICS_SUCCESS', [type, metrics]);
  });

const defaultPollIntervalMs = 45000; // 45 seconds
const defaultTimeslicePeriodMs = moment.duration(1, 'days').asMilliseconds();
const ALL_DATA = 99999;

export default {
  namespaced: true,

  state: {
    instant: {
      pollIntervalMs: defaultPollIntervalMs,
      lastFetchedAt: null,
      data: null,
    },
    timeslice: {
      pollIntervalMs: defaultPollIntervalMs,
      periodMs: defaultTimeslicePeriodMs,
      lastFetchedAt: null,
      data: null,
    },
    agents: {
      pollIntervalMs: defaultPollIntervalMs,
      periodMs: defaultTimeslicePeriodMs,
      lastFetchedAt: null,
      data: null,
    },
  },

  getters: {
    instantPollIntervalMs: (state) => state.instant.pollIntervalMs,
    timeslicePollIntervalMs: (state) => state.instant.pollIntervalMs,
  },

  mutations: {
    GET_METRICS_SUCCESS(state, [type, item]) {
      state[type].data = item;
      state[type].lastFetchedAt = Date.now();
    },
  },

  actions: {
    async getInstantMetrics(context, params) {
      const now = moment();
      params.period_start = now.unix();
      params.period_end = now.unix();
      return await getMetrics('instant', context, params, 'post');
    },
    async getTimesliceMetrics(context, params) {
      params.period_start = moment().startOf('day').unix();
      params.period_end = moment().unix();
      return await getMetrics('timeslice', context, params, 'post');
    },
    async getAgentsMetrics(context, params) {
      params.status = 'active';
      params.limit = ALL_DATA;
      return await getMetrics('agents', context, { params }, 'get');
    },
  },
};
