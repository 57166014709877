<template lang="pug">
aiq-tooltip(effect="light",
            placement="right",
            :show-after="2000"
            :hide-after="5"
            popper-class="conversation-item-tooltip")
  template(v-slot:content)
    .container
      tooltip-body(:summaryData="summaryData")
  .conversation-item(:class="className" @mouseenter="startHoverTimer" @mouseleave="cancelHoverTimer" @click="$emit('click', item)")
    aiq-badge.conversation-timer-badge(:value="conversationTimer"
                                      :hidden="!showTimer"
                                      :class="{ 'grey-out-badge': isInactive, 'red-badge': isActive }")
      .conversation-item-channel-icon
        channel-icon(:conversation="item" :customer="item.customer" :selected="selected")

    .conversation-item-name {{ conversationName }}
    .conversation-item-status
      aiq-tooltip(effect="dark",
                v-if="segment && segment.icon_url"
                :content="segment && segment.name",
                placement="top",
                :show-after="500")
        aiq-image(
            :key="segment.icon_url"
            :src="segment.icon_url"
            class="segment-icon")
      aiq-tooltip(effect="dark",
                  content="Is locked by Bot",
                  placement="top",
                  v-if='item.isBotInProgress'
                  :show-after="1000")
        ChatBotIcon.bot-icon(:class="{ selected: selected }")
      aiq-tooltip(effect="dark",
                  content="Closed",
                  placement="top",
                  v-if="closed"
                  :show-after="1000")
        i.el-icon-check
      aiq-tooltip(effect="dark",
                  content="Primary Customer",
                  v-if='isPrimaryCustomer'
                  placement="top",
                  :show-after="500")
        PrimaryAgentIcon.primary-icon(:class="{ selected: selected }")
      aiq-tooltip(effect="dark",
                  :content="tooltipContentStarred",
                  placement="top",
                  :show-after="500")
        aiq-button.watchlist(:class="{'isset': isStarred}"
                            @click.stop="$emit('starClick', item)")
          i.iq-ico-item
      aiq-button(v-if="deletable" @click.stop="$emit('delete', item)")
        i.el-icon-close

</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import get from 'lodash/get';
import has from 'lodash/has';
import isNull from 'lodash/isNull';
import sortBy from 'lodash/sortBy';
import { isActive, isInactive, IMPORTANCE } from '@/constants';
import { BOT_EMAIL } from '@/constants/agent';
import PrimaryAgentIcon from '@/assets/svg/primary-indicator-white.vue';
import ChatBotIcon from '@/assets/svg/chatbot.vue';
import { formatCustomerSegmentsForDisplay } from '@/libs/customerSegments';

import ChannelIcon from '@/components/ChannelIcon.vue';
import TooltipBody from './TooltipBody.vue';

const MAX_UNREAD_MSGS = 9;
const conversation_timer_interval_ms = 1000;
const one_minute = moment.duration(1, 'minute');
const one_hour = moment.duration(1, 'hour');
const one_day = moment.duration(1, 'day');
const one_week = moment.duration(1, 'week');
const one_month = moment.duration(1, 'month');
const one_year = moment.duration(1, 'year');
function getConversationProperty(conversation, property, initialVal = null) {
  return get(conversation, property, initialVal);
}

const NOT_AVAILABLE_OBJ = {
  value: 'N/A',
  type: 'string',
};

const TIMEOUT_TO_GET_SUMMARY = 500;

export default {
  name: 'conversationItem',
  components: {
    PrimaryAgentIcon,
    ChatBotIcon,
    ChannelIcon,
    TooltipBody,
  },
  data() {
    return {
      conversationTimer: '',
      maxUnreadMessages: MAX_UNREAD_MSGS,
      hoverTimeout: null,
      summaryData: [],
    };
  },
  props: {
    agent: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    highlighted: Boolean,
    selected: {
      type: Boolean,
    },
    closed: {
      type: Boolean,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    item(newItem) {
      if (!newItem.showTimer) {
        this.stopTimer();
      } else if (newItem.showTimer && !this.conversationTimer) {
        this.startTimer();
      }
    },
  },
  mounted() {
    if (this.showTimer) {
      this.startTimer();
    }
  },
  computed: {
    ...mapGetters({
      segments: 'configs/segments',
    }),
    isPrimaryCustomer() {
      return get(this.item, 'customer.primary_agent') === get(this.agent, 'id');
    },
    className() {
      return {
        'is-inactive': this.isInactive,
        'is-selected': this.selected,
        'is-highlighted': this.highlighted && !this.selected,
        'is-escalated': this.isEscalated && !this.selected,
      };
    },
    isInactive() {
      return isInactive(this.item);
    },
    isActive() {
      return isActive(this.item);
    },
    isStarred() {
      return getConversationProperty(this.item, 'isStarred');
    },
    isEscalated() {
      return getConversationProperty(this.item, 'importance') === IMPORTANCE.ESCALATED;
    },
    tooltipContentStarred() {
      return this.isStarred ? 'Remove from Watch List' : 'Add to Watch List';
    },
    showTimer() {
      return getConversationProperty(this.item, 'showTimer');
    },
    showUnreadCount() {
      return !this.selected && this.unreadMessageCount;
    },
    channel() {
      return getConversationProperty(this.item, 'channel');
    },
    conversationName() {
      if (!this.item) {
        return '';
      }

      const { customer = {}, customer_id = 0 } = this.item;
      const { displayName = '', first_name = '', last_name = '' } =
        customer.profile || {};

      const customerName = `${first_name} ${last_name}`.trim() || displayName;
      return customerName
        || this.$t('conversation_tab.left_panel.conversation_item.default_title', { customer_id });
    },
    unreadMessageCount() {
      return getConversationProperty(this.item, 'unread_message_count');
    },
    customerSegments() {
      const segmentNames = getConversationProperty(this.item, 'customer.customer_segments', []);
      return isNull(segmentNames) ? [] : segmentNames.map(formatCustomerSegmentsForDisplay);
    },
    segment() {
      const segment = sortBy(this.segments, s => s.priority)
        .find(s => this.customerSegments.includes(s.name));
      return segment;
    },
    lastSender() {
      const botId = get(this.$store.getters['conversations/bot'], 'id');
      const senderId = get(this.item, 'latest_message.sender_id');
      const senderType = get(this.item, 'latest_message.sender_type');
      return senderId === botId ? this.LAST_SENDER_TYPES.bot : this.LAST_SENDER_TYPES[senderType];
    },
    LAST_SENDER_TYPES() {
      return {
        customers: this.$t('conversation_tab.left_panel.hover_popup.last_sender_type.customer'),
        agents: 'Agent',
        bot: 'Bot',
      };
    },
  },
  beforeUnmount() {
    this.stopTimer();
  },
  methods: {
    formatTimer(ago) {
      const diff = moment.duration(moment() - ago);

      if (diff < 0) {
        return '';
      }

      if (diff < one_minute) {
        return `${diff.get('second')}s`;
      }

      if (diff <= one_hour) {
        return `${diff.get('minute')}m`;
      }

      if (diff <= one_day) {
        return `${diff.get('hour')}h`;
      }

      if (diff <= one_week) {
        return `${diff.get('day')}d`;
      }

      if (diff <= one_month) {
        return `${diff.get('week')}w`;
      }

      if (diff <= one_year) {
        return `${diff.get('month')}mo`;
      }

      return `${diff.get('year')}y`;
    },
    startTimer() {
      if (!this.item) {
        return;
      }

      if (!this.item.showTimer) {
        return;
      }

      const date = new Date(this.item.lastUnansweredTimestamp);
      this.conversationTimer = this.conversationTimer
        ? ''
        : this.formatTimer(date);
      this.conversationTimerInterval = window.setInterval(() => {
        this.conversationTimer = this.formatTimer(date);
      }, conversation_timer_interval_ms);
    },
    stopTimer() {
      if (!this.conversationTimerInterval) {
        return;
      }

      this.conversationTimer = null;
      window.clearInterval(this.conversationTimerInterval);
    },
    startHoverTimer() {
      this.hoverTimeout = setTimeout(this.getConversationSummaryData, TIMEOUT_TO_GET_SUMMARY);
    },
    cancelHoverTimer() {
      clearTimeout(this.hoverTimeout);
    },
    getConversationSummaryData() {
      this.$store.dispatch('conversations/getConversation', {
        conversationId: this.item.id,
        customerId: this.item.customer_id,
      }).then(({ primaryAgent, agents }) => {
        const assignedAgents = agents
          .filter(({ id, email }) => email !== BOT_EMAIL && id !== get(primaryAgent, 'id'))
          .map((agent) => agent.fullName);
        this.summaryData = [
          {
            label: 'Primary Agent',
            ...(has(primaryAgent, 'id') ? {
              value: primaryAgent.fullName,
              type: 'string',
            } : NOT_AVAILABLE_OBJ),
          },
          {
            label: 'Assigned Agents',
            ...(assignedAgents.length ? {
              value: assignedAgents,
              type: 'list',
            } : NOT_AVAILABLE_OBJ),
          },
          {
            label: 'Last Sender',
            type: 'string',
            value: this.lastSender,
          },
          {
            label: 'Last Message',
            type: 'message',
            value: this.item.latest_message,
          },
          {
            label: `Conversation ${this.item.isBotInProgress ? 'IS' : 'is NOT'} bot locked`,
          },
        ];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-variables.scss";

$offer-button-bg-color: #5993ff;
$selected-conversation-bg-color: #4671b3;
$highlight-bg-color: #ecf2fb;
$reviewed-unselected-icon-color: #000;
$reviewed-selected-icon-color: #fff;

.conversation-item {
  margin-bottom: 2px;
  color: #41474d;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  height: 40px;

  &.is-inactive {
    background-color: #eff2f7;
  }
  &:hover {
    background-color: $highlight-bg-color;
  }
  &.is-highlighted {
    background-color: $highlight-bg-color;
  }
  &.is-selected {
    color: #fff;
    font-weight: 700;
    line-height: 16px;
    white-space: normal;
    word-break: break-all;
    background-color: $selected-conversation-bg-color;

    &:hover {
      background-color: $selected-conversation-bg-color;
    }
  }
  &.is-escalated {
    background-color: #e6a23c;
    color: white;
    :deep(.channel-icon), svg {
      fill: white !important;
      stroke: white !important;
    }
  }
}

.conversation-item {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  height: 40px;
  padding: 0px 16px 0px 0px;

  &.non-active {
    background: #eff2f7;
  }
}
.conversation-item-channel-icon {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 14px;
}
.conversation-item-name {
  flex: 1 0 0%;
  padding-right: 15px;
  font-size: 14px;
  font-weight: 300;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &::selection {
    color: inherit;
    background-color: transparent;
  }

  &:hover {
    cursor: pointer;
  }
}
.conversation-item-status {
  line-height: 13px;
  display: flex;
  align-items: center;

  .el-button {
    padding: 0;
    margin: 0 6px 0 0;
    line-height: 13px;
    vertical-align: middle;
    border: 0;
    opacity: 0.25;
    background-color: transparent;
    overflow: visible;

    &:last-child {
      margin-right: 0;
    }
    &.isset {
      opacity: 1;
      color: $reviewed-unselected-icon-color;
    }

    i:hover {
      color: #5993FF;
    }
  }

  .disabled-compatible-tooltip-content-wrapper {
    margin-right: 6px;
  }

  i.el-icon-check {
    font-size: 10px;
    margin: 2px 10px 0px 0px;
  }
}

[class^='iq-ico']::before {
  display: inline-block;
  content: '';
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.is-selected, .is-escalated {
  .watchlist {
    .iq-ico-item {
      &::before {
        width: 12px;
        height: 12px;

        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='12px'%3E %3Cpath fill-rule='evenodd' fill='rgb(255, 255, 255)' d='M2.518,11.997 C2.442,11.997 2.367,11.972 2.302,11.924 C2.189,11.838 2.133,11.692 2.156,11.547 L2.788,7.685 L0.111,4.949 C0.011,4.847 -0.025,4.694 0.018,4.554 C0.061,4.415 0.176,4.313 0.315,4.292 L4.014,3.728 L5.669,0.214 C5.731,0.083 5.859,-0.000 5.998,-0.000 C6.138,-0.000 6.266,0.083 6.328,0.214 L7.983,3.728 L11.683,4.292 C11.821,4.313 11.936,4.415 11.979,4.554 C12.022,4.694 11.986,4.847 11.886,4.949 L9.209,7.685 L9.841,11.547 C9.865,11.691 9.808,11.838 9.695,11.924 C9.581,12.010 9.431,12.021 9.308,11.953 L5.999,10.130 L2.690,11.953 C2.636,11.983 2.577,11.997 2.518,11.997 ZM1.158,4.943 L3.440,7.274 C3.527,7.363 3.566,7.490 3.546,7.616 L3.007,10.908 L5.828,9.353 C5.935,9.294 6.063,9.294 6.170,9.353 L8.991,10.908 L8.452,7.616 C8.432,7.490 8.471,7.363 8.558,7.274 L10.840,4.943 L7.686,4.462 C7.566,4.444 7.463,4.365 7.409,4.251 L5.999,1.256 L4.588,4.251 C4.535,4.365 4.431,4.444 4.312,4.462 L1.158,4.943 Z'/%3E %3C/svg%3E");
      }
    }

    &.isset {
      .iq-ico-item {
        &::before {
          width: 12px;
          height: 12px;

          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='12px'%3E %3Cpath fill-rule='evenodd' fill='rgb(255, 255, 255)' d='M5.434,0.369 L3.925,3.579 L0.530,4.105 C0.304,4.132 0.103,4.316 0.027,4.553 C-0.048,4.789 0.027,5.052 0.178,5.237 L2.643,7.736 L2.064,11.262 C2.014,11.499 2.115,11.762 2.316,11.894 C2.517,12.052 2.768,12.052 2.970,11.947 L5.987,10.289 L9.030,11.947 C9.231,12.052 9.508,12.052 9.684,11.894 C9.885,11.736 9.985,11.499 9.935,11.262 L9.357,7.736 L11.821,5.237 C11.997,5.052 12.048,4.789 11.972,4.553 C11.897,4.316 11.695,4.132 11.469,4.105 L8.074,3.579 L6.566,0.369 C6.440,0.132 6.239,0.000 5.987,0.000 C5.736,0.000 5.535,0.132 5.434,0.369 Z'/%3E %3C/svg%3E");
        }
      }
    }
  }
}

.watchlist {
  .iq-ico-item {
    &::before {
      width: 12px;
      height: 12px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='12px'%3E %3Cpath fill-rule='evenodd' fill='rgb(83, 100, 128)' d='M2.518,11.997 C2.442,11.997 2.367,11.972 2.302,11.924 C2.189,11.838 2.133,11.692 2.156,11.547 L2.788,7.685 L0.111,4.949 C0.011,4.847 -0.025,4.694 0.018,4.554 C0.061,4.415 0.176,4.313 0.315,4.292 L4.014,3.728 L5.669,0.214 C5.731,0.083 5.859,-0.000 5.998,-0.000 C6.138,-0.000 6.266,0.083 6.328,0.214 L7.983,3.728 L11.683,4.292 C11.821,4.313 11.936,4.415 11.979,4.554 C12.022,4.694 11.986,4.847 11.886,4.949 L9.209,7.685 L9.841,11.547 C9.865,11.691 9.808,11.838 9.695,11.924 C9.581,12.010 9.431,12.021 9.308,11.953 L5.999,10.130 L2.690,11.953 C2.636,11.983 2.577,11.997 2.518,11.997 ZM1.158,4.943 L3.440,7.274 C3.527,7.363 3.566,7.490 3.546,7.616 L3.007,10.908 L5.828,9.353 C5.935,9.294 6.063,9.294 6.170,9.353 L8.991,10.908 L8.452,7.616 C8.432,7.490 8.471,7.363 8.558,7.274 L10.840,4.943 L7.686,4.462 C7.566,4.444 7.463,4.365 7.409,4.251 L5.999,1.256 L4.588,4.251 C4.535,4.365 4.431,4.444 4.312,4.462 L1.158,4.943 Z'/%3E %3C/svg%3E");
    }
  }

  &.isset {
    .iq-ico-item {
      &::before {
        width: 12px;
        height: 12px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='12px'%3E %3Cpath fill-rule='evenodd' fill='rgb(83, 100, 128)' d='M5.434,0.369 L3.925,3.579 L0.530,4.105 C0.304,4.132 0.103,4.316 0.027,4.553 C-0.048,4.789 0.027,5.052 0.178,5.237 L2.643,7.736 L2.064,11.262 C2.014,11.499 2.115,11.762 2.316,11.894 C2.517,12.052 2.768,12.052 2.970,11.947 L5.987,10.289 L9.030,11.947 C9.231,12.052 9.508,12.052 9.684,11.894 C9.885,11.736 9.985,11.499 9.935,11.262 L9.357,7.736 L11.821,5.237 C11.997,5.052 12.048,4.789 11.972,4.553 C11.897,4.316 11.695,4.132 11.469,4.105 L8.074,3.579 L6.566,0.369 C6.440,0.132 6.239,0.000 5.987,0.000 C5.736,0.000 5.535,0.132 5.434,0.369 Z'/%3E %3C/svg%3E");
      }
    }
  }
}

.primary-icon {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  fill: #495975;
  stroke: #495975;
}

.primary-icon.selected {
  fill: white;
  stroke: white;
}

.channel-icon {
  width: 16px;
  height: 16px;
  fill: #4671b3;
  stroke: #4671b3;
}

.channel-icon.selected {
  fill: white;
  stroke: white;
}

.bot-icon {
  width: 28px;
  height: 28px;
  margin-right: 4px;
  outline: 0;

  &.selected {
    fill: white;
    stroke: white;
  }
}

.segment-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
</style>

<style lang="scss">
@import "../../styles/aiq-variables.scss";

.conversation-timer-badge {
  sup.el-badge__content.is-fixed {
    height: 14px;
    font-size: 11px;
    line-height: 14px;
    top: -2px;
    right: 40px;
    border-color: transparent;
  }
}

.red-badge {
  sup.el-badge__content.is-fixed {
    background: #f44336;
  }
}

.grey-out-badge {
  sup.el-badge__content.is-fixed {
    background: #475669;
  }
}

.conversation-item-tooltip.el-popper {
  max-width: 400px;
  border-color: $aiq-bdc-conversation-item-tooltip;
  & .el-popper__arrow::before {
    border-color: $aiq-bdc-conversation-item-tooltip;
  }
  .container {
    width: 400px;
    min-height: 192px;
    .spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .row {
      padding: 5px 0;

      .value {
        font-weight: 100;
      }
      .center {
        display: flex;
        justify-content: center;
      }

      .message-wrapper.message-agent {
        padding-left: 0;
      }

      .message-container {
        padding: 0;
        width: 100%;

        .message-avatar {
          display: none;
        }

        .message-bubble {
          flex: 1;

          .message-bubble_header {
            .message-bubble_date {
              width: 100%;
            }
          }
        }
        .msg-suggestions-container {
          display: none;
        }
      }
    }
  }
}
</style>
