<template lang="pug">
  management-page(title="Summary"
                :creatable="false"
                :searchable="false"
                :showHeader="false")
    template(v-slot:body)
      .chart-configuration
        .time-range-filter
          aiq-date-picker(v-model="dateRange"
                          @change="handleDatePickerChange($event)"
                          type="daterange"
                          placeholder="Select date range",
                          popper-class="metrics-time-range__date-picker-popup"
                          :shortcuts="datePickerOptions.shortcuts"
                          :disabled-date="datePickerOptions.disabledDate"
                          :teleported="false"
                          format="YYYY/MM/DD"
                          :clearable="false")
      .chart-grid
        aiq-row(:gutter="16")
          aiq-col(:span="12")
            chart(:dateRange="dateRangeUtc"
                            :chartConfig="suggestedResponsesChartConfig"
                            yLabel="Count"
                            :shouldHandlePartialDates="true"
                            :shouldChangeXLabels="false"
                            @onChartDataChange="onChartDataChange($event,'suggestionsChartData')"
                            @onChartBreakdownChange="suggestionsBucketOption=$event"
                            :formatXTick="xAxisFormatForSuggestions"
                            :reportFileName="returnReportFileName('Suggestions')"
                            )
          aiq-col(:span="12")
            chart(:dateRange="dateRangeUtc"
                            :chartConfig="dialogsChartConfig"
                            yLabel="Count"
                            :shouldHandlePartialDates="true"
                            :shouldChangeXLabels="false"
                            @onChartDataChange="onChartDataChange($event,'dialogsChartData')"
                            @onChartBreakdownChange="dialogsBucketOption=$event"
                            :formatXTick="xAxisFormatForDialogs"
                            :reportFileName="returnReportFileName('Dialogs')"
                            )
          aiq-col(:span="12")
            chart(:dateRange="dateRangeUtc"
                            :chartConfig="workflowsChartConfig"
                            yLabel="Count"
                            :shouldHandlePartialDates="true"
                            :shouldChangeXLabels="false"
                            @onChartDataChange="onChartDataChange($event,'workflowsChartData')"
                            @onChartBreakdownChange="workflowsBucketOption=$event"
                            :formatXTick="xAxisFormatForWorkflows"
                            :reportFileName="returnReportFileName('Workflows')"
                            )
          aiq-col(:span="12")
            chart(:dateRange="dateRangeUtc"
                            :chartConfig="assetsChartConfig"
                            yLabel="Count"
                            :shouldHandlePartialDates="true"
                            :shouldChangeXLabels="false"
                            @onChartDataChange="onChartDataChange($event,'assetsChartData')"
                            @onChartBreakdownChange="assetsBucketOption=$event"
                            :formatXTick="xAxisFormatForAssets"
                            :reportFileName="returnReportFileName('Assets')"
                            )
          aiq-col(:span="12")
            chart(:dateRange="dateRangeUtc"
                            :chartConfig="documentsChartConfig"
                            yLabel="Count"
                            :shouldHandlePartialDates="true"
                            :shouldChangeXLabels="false"
                            @onChartDataChange="onChartDataChange($event,'documentsChartData')"
                            @onChartBreakdownChange="documentsBucketOption=$event"
                            :formatXTick="xAxisFormatForDocuments"
                            :reportFileName="returnReportFileName('Documents')"
                            )
</template>

<script>
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import Chart from '@/components/Chart.vue';
import { dstDateRangeUtc, datePickerDefaultOptions, setToEndOfDay, defaultDateRange, getNonReactiveDateRange, lastAvailableMonthAndQuarterRange, createPickerDateRangeOption } from '../../configs/dateRange';
import { assetsChartConfig, dialogsChartConfig, documentsChartConfig, suggestedResponsesChartConfig, workflowsChartConfig } from './constants';
import moment from 'moment';

export default {
  pageResource: '/metrics',
  components: {
    Chart,
    ManagementPage,
  },
  computed: {
    datePickerOptions() {
      return {
        ...datePickerDefaultOptions(),
        shortcuts: lastAvailableMonthAndQuarterRange.map(createPickerDateRangeOption),
      };
    },
    dateRangeUtc() {
      return dstDateRangeUtc(this.dateRange);
    },
  },
  data() {
    return {
      assetsChartConfig,
      documentsChartConfig,
      suggestedResponsesChartConfig,
      dialogsChartConfig,
      workflowsChartConfig,
      percentFormatter: v => `${v}%`,
      durationFormatter: this.$aiq.formatters.timeDelta,
      dateRange: defaultDateRange(),
    };
  },
  methods: {
    handleDatePickerChange(newDateRange) {
      this.dateRange = setToEndOfDay(getNonReactiveDateRange(newDateRange));
    },
    returnReportFileName(chartName = 'data'){
      const [startTimestamp, endTimestamp] = this.dateRange;
      const startDate = moment.isMoment(startTimestamp)
        ? startTimestamp.format('YYYY-MM-DD')
        : moment(startTimestamp).format('YYYY-MM-DD');
      const endDate = moment.isMoment(endTimestamp)
        ? endTimestamp.format('YYYY-MM-DD')
        : moment(endTimestamp).format('YYYY-MM-DD');
      return `${chartName.replaceAll(' ','_')}_${startDate}_${endDate}`;
    },
    onChartDataChange(data, chartName) {
      const dataPoints = data?.dataset?.[0]?.data_points || [];
      this[chartName] = dataPoints;
    },
    isDataPartial(date, chartName) {
      const timeStamp = new Date(date).getTime();
      const isPartial = this?.[chartName]?.find(item=> item.x === timeStamp)?.isPartial;
      return isPartial;
    },
    xAxisFormatForSuggestions(date) {
      const format = this.suggestionsBucketOption?.value === 'month' ? 'MMM' : 'MMM DD';
      return `${moment(date).format(format)} ${this.isDataPartial(date, 'suggestionsChartData') ? ' *' : ''}`;
    },
    xAxisFormatForDialogs(date) {
      const format = this.dialogsBucketOption?.value === 'month' ? 'MMM' : 'MMM DD';
      return `${moment(date).format(format)} ${this.isDataPartial(date, 'dialogsChartData') ? ' *' : ''}`;
    },
    xAxisFormatForWorkflows(date) {
      const format = this.workflowsBucketOption?.value === 'month' ? 'MMM' : 'MMM DD';
      return `${moment(date).format(format)} ${this.isDataPartial(date, 'workflowsChartData') ? ' *' : ''}`;
    },
    xAxisFormatForAssets(date) {
      const format = this.documentsBucketOption?.value === 'month' ? 'MMM' : 'MMM DD';
      return `${moment(date).format(format)} ${this.isDataPartial(date, 'documentsChartData') ? ' *' : ''}`;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/aiq-variables.scss";

.content-inner.summary {
  overflow-y: auto;
}

.time-range-filter {
  position: relative;
  margin-left: auto;
}

.el-card:not(:first-of-type) {
  margin-top: 24px;
}

.chart-configuration {
  display: flex;
  margin-bottom: 24px;

  .members-filter {
    display: flex;
    align-items: center;
  }

  .breakdown {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .export-command {
    display: flex;
    height: 30px;
    width: 40px;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    padding: 0;
  }
}
</style>
