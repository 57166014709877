<template lang="pug">
  .counters-container
    aiq-button.all(:class="{'selected': selected === 'all', 'alert': allAlert}", @click="select('all')" v-if="canView('/conversations/all')")
      .counter-number {{ all }}
      .counter-text All
    aiq-button.team(:class="{'selected': selected === 'team', 'alert': allAlert}", @click="select('team')" v-if="canView('/conversations/team')")
      .counter-number {{ team }}
      .counter-text Team
    aiq-button.queue(:class="{'selected': selected === 'queue', 'alert': allAlert}", @click="select('queue')" v-if="canView('/conversations/queue')")
      .counter-number {{ queue }}
      .counter-text Queue
    aiq-button.mine(:class="{'selected': selected === 'mine', 'alert': mineAlert}", @click="select('mine')" v-if="canView('/conversations/mine')")
      .counter-number {{ mine }}
      .counter-text Mine
</template>

<script>

const WATCHER_MS = 1000;

const watcherForAlert = key => function cb(value) {
  if (value === 0) return;
  this[key] = true;
  window.setTimeout(() => {
    this[key] = false;
  }, WATCHER_MS);
};

export default {
  name: 'countersPanel',
  props: {
    all: {
      type: Number,
      default: () => 0,
    },
    team: {
      type: Number,
      default: () => 0,
    },
    queue: {
      type: Number,
      default: () => 0,
    },
    mine: {
      type: Number,
      default: () => 0,
    },
    unread: {
      type: Number,
      default: () => 0,
    },
    starred: {
      type: Number,
      default: () => 0,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  emits: ['select'],
  data() {
    return {
      allAlert: false,
      teamAlert: false,
      mineAlert: false,
      unreadAlert: false,
      starredAlert: false,
    };
  },
  watch: {
    all: {
      handler: watcherForAlert('allAlert'),
    },
    team: {
      handler: watcherForAlert('teamAlert'),
    },
    mine: {
      handler: watcherForAlert('mineAlert'),
    },
    unread: {
      handler: watcherForAlert('unreadAlert'),
    },
    starred: {
      handler: watcherForAlert('starredAlert'),
    },
  },
  methods: {
    select(tab) {
      this.$emit('select', tab);
    },
  },
};
</script>

<style lang="scss">
.counters-container {
  .el-button span {
    display: unset;
    align-items: unset;
  }
}
</style>

<style lang="scss" scoped>
@import "./../../../styles/aiq-variables.scss";

@mixin counter($color, $alert-color) {
  &.selected {
  border-bottom: 4px solid $color;
  }

  &.alert {
    color: #fff;
    background-color: $alert-color;
  }
}

.counters-container {
  display: flex;
  border-radius: 4px 4px 0 0;
  overflow: hidden;

  .el-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 0;
    height: 66px;
    min-height: initial;
    padding: 0;
    margin: 0;
    color: $aiq-text-counters-filter;
    background-color: $aiq-bgc-counters-filter;
    font-size: 12px;
    font-weight: 100;
    border: 0;
    border-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    text-transform: none;
    border-bottom: 4px solid #E6E9F0;

    &.selected {
      font-weight: 500;
      background-color: #ffffff;
    }
  }
  .counter-number {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 7px;
    font-size: 18px;
    line-height: 21px;
  }
  .counter-text {
    line-height: 12px;
  }
  .all {
    @include counter($aiq-bdc-counters-filter-all, $aiq-bgc-counters-filter-all)
  }
  .team {
    @include counter($aiq-bdc-counters-filter-team, $aiq-bgc-counters-filter-team)
  }
  .queue {
    @include counter($aiq-bdc-counters-filter-queue, $aiq-bgc-counters-filter-queue)
  }
  .mine {
    @include counter($aiq-bdc-counters-filter-mine, $aiq-bgc-counters-filter-mine)
  }
  .unread {
    @include counter($aiq-bdc-counters-filter-unread, $aiq-bgc-counters-filter-unread)
  }
  .starred {
    @include counter($aiq-bdc-counters-filter-starred, $aiq-bgc-counters-filter-starred)
  }

}
</style>
