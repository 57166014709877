<template lang="pug">
managementPage.scrollable(ref="usersTable"
              title="Agents"
              @fetchData="debouncedLoadList"
              @openEditor="openEditor"
              :searchPageSize="searchPageSize",
              :showTotals="true"
              :pagination="pagination"
              :loadWithQuery="loadWithQuery"
              :creatable="canView('/settings/agents/manage/new')"
              :creationTooltipContent="$t('settings_tab.admin_tab.manage_tab.add_agent_tooltip')")
  template(v-slot:filters)
    .select-box-container.teams-filter.filter-in-header
      aiq-select(placeholder="Access"
                  v-model="statusSelectedOption"
                  size="small"
                  collapse-tags
                  :suffix-icon="Icon.CaretBottom"
                  @change="$refs.usersTable.resetAndFetchData()")
        aiq-option(v-for="item in statusOptions", :key="item", :label="item", :value="item")
    .select-box-container.roles-filter.filter-in-header
      aiq-select(placeholder="Roles"
                  v-model="roleSelectedOptions"
                  size="small"
                  collapse-tags
                  multiple
                  :reserve-keyword="false"
                  :suffix-icon="Icon.CaretBottom"
                  @change="$refs.usersTable.resetAndFetchData()")
          aiq-option(v-for="option in roles", :key="option.name", :label="option.label", :value="option.name")
    .select-box-container.availability-filter.filter-in-header
      aiq-select(v-if="includeSubstatus"
                placeholder="Current Status"
                v-model="availabilitySelectedOptions"
                size="small"
                collapse-tags
                multiple
                :reserve-keyword="false"
                :suffix-icon="Icon.CaretBottom"
                @change="$refs.usersTable.resetAndFetchData()")
        aiq-option(v-for="option in agentStatusOptions", :key="option.id", :label="option.label" :value="option.id")
          agent-status(:available="option.status" :subStatus="option.subStatus" :show-label="true")
      aiq-select(v-else
                placeholder="Current Status"
                v-model="availabilitySelectedOptions"
                size="small"
                collapse-tags
                multiple
                :reserve-keyword="false"
                :suffix-icon="Icon.CaretBottom"
                @change="$refs.usersTable.resetAndFetchData()")
        aiq-option(v-for="option in availabilityOptions", :key="option.field", :label="option.label", :value="option.field")
  router-view(v-slot:editor)
  template(v-slot:body)
    aiq-table.main-contents(:data="items" v-loading="loading" @sort-change="handleSortChange")
      aiq-table-column(width="68", label-class-name="is-bordered")
        template(v-slot:default="scope")
          aiq-image.aiq-img-thumb(:key="scope.row.id" :src="$_get(scope.row, 'picture.payload.url', profileIcon)")
      aiq-table-column(label="Name", prop="fullName", :sortable="true", :sort-method="onSortName", label-class-name="is-bordered")
      aiq-table-column(label="Roles", width="200", :sort-method="onSortRole", :sortable="true", label-class-name="is-bordered")
        template(v-slot:default="scope")
          span(v-for="role in scope.row.roles") {{ $filters.toUpperFirst(role) }} &nbsp;
      aiq-table-column(width="200", label="Current Status", :sort-method="onSortAvailable", :sortable="true", label-class-name="is-bordered")
        template(v-slot:default="scope")
          agent-status(:available="scope.row.available" :subStatus="scope.row.busy_status.status" :show-label="true")
      aiq-table-column(width="130", label="Out of Office", :sort-method="onSortOutOfOffice", :sortable="true", label-class-name="is-bordered")
        template(v-slot:default="scope")
          i(:class="{'el-icon-check': busyStatusVacation(scope.row.busy_status.status)}")
      aiq-table-column(width="200", label="Primary Capacity", :sort-method="(a, b) => onSortCapacity(a, b, 'max_load', 'totalPrimaryCustomers')", :sortable="true", label-class-name="is-bordered")
        template(v-slot:header)
          span Primary Capacity &nbsp;
          aiq-tooltip(effect="dark"
                      placement="bottom"
                      popper-class="explanation-tooltip"
                      raw-content
                      :content="$filters.sanitize($t('global.primary_capacity_explanation'))"
                      :show-after="1")
            i.iq-ico-explanation
        template(v-slot:default="scope")
          span {{ capacityFormatter(scope.row.totalPrimaryCustomers, scope.row.max_load) }}
      aiq-table-column(width="200", label="Concurrent Capacity", :sort-method="(a, b) => onSortCapacity(a, b, 'concurrent_capacity', 'totalLocks')", :sortable="true", label-class-name="is-bordered")
        template(v-slot:header)
          span Concurrent Capacity &nbsp;
          aiq-tooltip(effect="dark"
                      placement="bottom"
                      popper-class="explanation-tooltip"
                      raw-content
                      :content="$filters.sanitize($t('global.concurrent_capacity_explanation'))"
                      :show-after="1")
            i.iq-ico-explanation
        template(v-slot:default="scope")
          span {{ capacityFormatter(scope.row.totalLocks, scope.row.concurrent_capacity) }}
      aiq-table-column(width="200", label="Dashboard Access", prop="deleted_at", :sort-method="onSortStatus" :sortable="true", label-class-name="is-bordered")
        template(v-slot:default="scope")
          span {{ statusFormatter(scope.row.deleted_at) }}
      aiq-table-column(v-if="canView('/settings/agents/manage/:number')" width="71", label-class-name="is-bordered")
        template(v-slot:default="scope")
          aiq-button(link type="primary" size="small", @click="openEditor(scope.row)") Edit
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import AgentStatus from '@/components/AgentStatus.vue';
import { AGENT_STATUS, TEAM_LIMIT, parse } from '@/constants';
import { PAGINATION_DEFAULT } from '@/constants/pagination';
import profileIcon from '@/assets/photo.jpg';
import { availabilitySort } from '@/libs/shared';
import { getAgentStatusOptions } from '@/libs';

const SEARCH_PAGE_SIZE = 1000;
const LOAD_DEBOUNCE = 100;

export default {
  name: 'manageAgents',
  components: {
    ManagementPage,
    AgentStatus,
  },
  data() {
    return {
      isLoading: false,
      profileIcon: profileIcon,
      pagination: PAGINATION_DEFAULT,
      loading: true,
      loadWithQuery: '',
      pageResource: '/settings/agents/manage',
      agentEditForm: false,
      temporalAgent: {},
      searchPageSize: SEARCH_PAGE_SIZE,
      roleSelectedOptions: [],
      availabilitySelectedOptions: [],
      statusSelectedOption: 'Active',
      statusOptions: ['All', 'Active', 'Inactive']
    };
  },
  computed: {
    ...mapGetters({ items: 'agents/sortedAgents', roles: 'settings/roleNames' }),
    ...mapState({
      selected: state => state.agents.selected,
      loaded: state => state.agents.agentsLoaded,
      includeSubstatus: state => state.featureFlags.FEATURE_FLAGS.SETTINGS_AGENT_STATUS,
      allAgentStatusOptions: state => get(state, 'settings.agentStatuses.statuses', []),
    }),
    availabilityOptions() {
      return Object.values(AGENT_STATUS);
    },
    agentStatusOptions() {
      return getAgentStatusOptions(this.allAgentStatusOptions);
    },
  },
  created() {
    this.loadWithQuery = get(this.$route, 'query.query');
    this.$_get = (object, key, defaultValue) => get(object, key, defaultValue);
  },
  mounted() {
    this.loadResources();
  },
  methods: {
    capacityFormatter(numerator, denominator) {
      return `${numerator}/${denominator}`;
    },
    statusFormatter(deleted_at) {
      return deleted_at ? 'Inactive' : 'Active';
    },
    busyStatusVacation(busyStatus) {
      return busyStatus === 'vacation';
    },
    onSortCapacity(a, b, totalCapacityProp, currentValueProp) {
      const aExcess = a[totalCapacityProp] - a[currentValueProp];
      const bExcess = b[totalCapacityProp] - b[currentValueProp];
      return aExcess <= bExcess ? 1 : -1;
    },
    onSortAvailable(a, b) {
      return availabilitySort(a.available, b.available);
    },
    onSortOutOfOffice(a, b) {
      const aStatus = a.busy_status.status || '';
      const bStatus = b.busy_status.status || '';
      return aStatus < bStatus ? 1 : -1;
    },
    onSortName(a, b) {
      const aValue = a.fullName.toLowerCase().replace(/\s+/g, '');
      const bValue = b.fullName.toLowerCase().replace(/\s+/g, '');
      return aValue < bValue ? 1 : -1;
    },
    onSortRole(a, b) {
      const roleA = a.roles.sort().join().toLowerCase();
      const roleB = b.roles.sort().join().toLowerCase();

      return roleA < roleB ? 1 : -1;
    },
    onSortStatus(a, b) {
      return this.statusFormatter(a.deleted_at).localeCompare(this.statusFormatter(b.deleted_at));
    },
    handleSortChange(){
      // Use nextTick to run logic after the sorting is done
      this.$nextTick(() => {
        window.dispatchEvent(new Event('resize'));
      });
    },
    debouncedLoadList: debounce(function (a) { this.loadList(a) }, LOAD_DEBOUNCE),
    loadResources() {
      this.$store.dispatch('teams/getTeamsList', [{ limit: TEAM_LIMIT }]);
      this.$store.dispatch('settings/getRolesMetadata');
      if (this.includeSubstatus) {
        this.$store.dispatch('settings/getSetting', { name: 'agent_status', stateName: 'agentStatuses' });
      }
    },
    loadList({searchTerm, searchOffset}) {
      const params = {
        limit: this.searchPageSize,
        query: searchTerm,
        offset: searchOffset,
      };

      if (this.statusSelectedOption) {
        params.status = this.statusSelectedOption.toLowerCase();
      }

      if (!isEmpty(this.roleSelectedOptions)) {
        params.roles = this.roleSelectedOptions;
      }

      if (!isEmpty(this.availabilitySelectedOptions)) {
        params.availabilities = this.availabilitySelectedOptions;
      }

      this.$store.dispatch('agents/getAgentsList', [
        params,
        searchOffset === 0,
      ]).then((response) => {
        this.loading = false;
        this.pagination = get(response, 'data.pagination', PAGINATION_DEFAULT);
      });
    },
    openEditor(record) {
      this.record = parse(this.$store.getters['agents/agentModel'], record);

      this.$store.dispatch('agents/selectAgent', this.record)
        .then(() => {
          const recordId = this.record.id || 'new';
          this.$store.dispatch('routing/setProps', {
            buttons: ['close', 'delete', 'save'],
            disabled: !!this.record.deleted_at,
          });
          this.$router.push({
            name: 'Settings.Agents.Manage.Edit',
            params: {
              id: recordId,
            },
          });
        });
    }
  },
};

</script>

<style lang="scss" scoped>
  @import "@/components/ManagementPage/ManagementPage.scss";
  .status-indicator {
    &::before {
      margin-top: 1px;
    }
  }

  .agents {

    .md-toolbar {
      min-height: 60px;
      padding: 14px 14px 10px;
    }
    .md-title {
      margin: 0;
      color: #6a7682;
      font-size: 21px;
      line-height: 21px;
    }
    .md-table-card {
      width: 100%;
    }
  }
  .photo-cell {
    width: 20%;
  }
  .name-cell {
    width: 20%;
  }
  .email-cell {
    width: 20%;
  }
  .available-cell {
    width: 20%;
  }
  .permissions-cell {
    width: 20%;
  }
  .add-agent {
    width: calc(40% - 36px);
    height: 32px;
    min-height: 32px;
    padding: 0;
    margin: 0 36px 0 0;
    color: #fff;
    text-transform: none;
    border-radius: 4px;
    background-color: #ffae00;

    .iq-ico-add {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
    &:hover {
      background-color: #fd8611 !important;
    }
  }
  .search-field {
    width: 60%;
    height: 32px;
    min-height: 32px;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    background-color: #e6f2fa;

    .iq-ico-search {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
    input {
      width: 100%;
      padding: 0 32px;
      font-size: 12px;
    }
  }
  .md-theme-agentiq.md-toolbar .md-input-container.search-field input {
    color: #6a7682;

    &::-webkit-input-placeholder {
      color: #6a7682;
      font-size: 12px;
    }
    &::-moz-placeholder {
      color: #6a7682;
      font-size: 12px;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #6a7682;
      font-size: 12px;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #6a7682;
      font-size: 12px;
    }
  }
  .aiq-img-thumb {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: block;
  }

  .el-tag-container {
    display: flex;
    justify-content: flex-start;
    direction: row;

    &> div {
      margin-left: 5px;
    }
  }
  .roles-filter,
  .availability-filter {
    width: 214px;
  }
</style>
