<template lang="pug">
  side-bar-vue(:baseResource="baseResource" :routeChildren="routeChildren")
</template>

<script>
import SideBarVue from '@/components/layouts/SideBarVue.vue';
import IQToolsRoutes from './IQTools.router';

export default {
  name: 'IQTools',
  components: {
    SideBarVue,
  },
  data() {
    return {
      baseResource: IQToolsRoutes.path,
      routeChildren: IQToolsRoutes.children
    };
  }
};
</script>

<style>
</style>
