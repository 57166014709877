<template lang="pug">
.template-variable
    .header
        h3.title {{$t('settings_tab.admin_tab.message_template.message_variable')}}
        i.el-icon-plus(@click="addVariable")
aiq-table(:data="variables")
    aiq-table-column(:label="$t('settings_tab.admin_tab.message_template.custom_header')"
                            label-class-name="is-bordered"
                            min-width="50"
                            :sort-method="onSortName"
                            :sortable="true")
      template(v-slot:default="scope")
        aiq-input(v-model="scope.row.name" ref="input" @keydown.enter="Save")
    aiq-table-column(:label="$t('settings_tab.admin_tab.message_template.values')"
                            label-class-name="is-bordered"
                            min-width="50")
        template(v-slot:default="scope")
            label(v-html="$filters.sanitize(`#{${scope.row.name}}`)")
            i.el-icon-close(@click="deleteOption(scope.row.name)" class="delete-icon")
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash';
const emptyTemplate = {
  name: ''
};
export default {
  name: 'template-variable',
  props: {
    variables: {
      type: Array,
      default: [],
    },
  },
  methods: {
    hasEmptyVariable() {
      return this.variables.some((variable) => {
        return isEmpty(variable.name);
      });
    },
    onSortName(a, b) {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    },
    addVariable() {
      this.variables.push(cloneDeep(emptyTemplate));
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    deleteOption(name) {
      this.$emit('delete', name);
    },
    Save() {
      if (!this.hasEmptyVariable()) {
        this.$emit('update', this.variables);
      } else {
        this.$aiq.notify.error(this.$t('settings_tab.admin_tab.message_template.error_field_template'));
      }
    }
  },
  emits: ['update', 'delete'],
};
</script>

<style lang="scss" scoped>

.header {
  display: flex;
  align-items: center;
}

.header .title {
  margin-right: 10px;
}
.delete-icon {
  color: #fff; 
  background-color: #ff0000; 
  border-radius: 50%; 
  font-size:12px;
  height: 12px;
  width: 12px;
  float: right;
  cursor: pointer;
}

:deep(.el-input__inner ) {
  height: 30px;
}
</style>