<template lang="pug">
layout-main(:hideHeader="isRealtimeContext")
  management-page.scrollable(title="MyPerformance"
                :creatable="false"
                :searchable="false"
                :showDownloadButton="true"
                :showHeader="false")
    template(v-slot:body)
      .header
        h1.title {{ myTabTitle }}
        .date_selector
          aiq-date-picker(v-model="dateRange"
                          type="daterange"
                          placeholder="Select date range",
                          popper-class="metrics-time-range__date-picker-popup"
                          :shortcuts="datePickerOptions.shortcuts"
                          :disabled-date="datePickerOptions.disabledDate"
                          :teleported="false"
                          format="YYYY/MM/DD"
                          :clearable="false")
      performance-grid(:categories="categories")
</template>

<script>
import LayoutMain from '@/components/layouts/main.vue';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import performanceMixin from '@/pages/Metrics/Agents/Performance';
import PerformanceGrid from '@/components/AgentPerformance/PerformanceGrid.vue';
import { mapState } from 'vuex';
import { durationFormatter } from '@/libs';
import { createPickerDateRangeOption, datePickerDefaultOptions, getDefaultDateRange, lastAvailableMonthAndQuarterRange } from '@/pages/Metrics/configs/dateRange';

export default {
  name: 'MyPerformance',
  components: {
    LayoutMain,
    ManagementPage,
    PerformanceGrid,
  },
  mixins: [performanceMixin],
  data() {
    return {
      fetchForCurrentAgent: true,
      dateRange: getDefaultDateRange()
    };
  },
  computed: {
    ...mapState({
      currentAgentID: state => state.agent.profile.id,
    }),
    datePickerOptions() {
      return {
        ...datePickerDefaultOptions(),
        shortcuts: lastAvailableMonthAndQuarterRange.map(createPickerDateRangeOption),
      };
    },
    categories() {
      return {
        communication: {
          title: 'Communication',
          metrics: [
            {
              tooltip:
                `This is the agent's total number of conversations${
                  this.durationTooltip}`,
              title: 'Total Number of Conversations',
              value: this.getNumberOfConversations(this.firstAgentPerf),
              duration: this.duration,
            },
            {
              tooltip:
                `This is the total number of messages sent by the agent${
                  this.durationTooltip}`,
              title: 'Total Number of Messages',
              value: this.getNumberOfMessages(this.firstAgentPerf),
              duration: this.duration,
            },
          ],
        },
        responsiveness: {
          title: 'Responsiveness',
          metrics: [
            {
              tooltip:
                `This is the agent's median first response time${
                  this.durationTooltip}`,
              title: 'First Response Time',
              value: this.getMedianFRT(this.firstAgentPerf) ? durationFormatter(this.getMedianFRT(this.firstAgentPerf)) : 'N/A',
              duration: this.duration,
            },
          ],
        },
        relationship: {
          title: 'Relationships',
          metrics: [
            {
              tooltip: this.$t('metrics_tab.agents_tab.performance_tab.average_feedback_duration_tooltip', {duration : this.durationTooltip} ), 
              title: this.$t('metrics_tab.agents_tab.performance_tab.average_feedback_score_label', 'Average Feedback Score'),
              value: this.getAvgRating(this.firstAgentPerf) === '' ? 'N/A' : this.getAvgRating(this.firstAgentPerf),
              duration: this.duration,
            },
            {
              tooltip:
                `This is the number of return ${this.$t('metrics_tab.agents_tab.customers', 'members')} that the agent has assisted${
                  this.durationTooltip}`,
              title: this.$t('metrics_tab.agents_tab.returning_customers', 'Returning Customers'),
              value: this.getnumberOfReturningCustomers(this.firstAgentPerf),
              duration: this.duration,
            },
            {
              tooltip:
                `This is the total number of ${this.$t('metrics_tab.agents_tab.customers', 'members')} the agent has assisted${
                  this.durationTooltip}`,
              title: this.$t('metrics_tab.agents_tab.customers', 'Members'),
              value: this.getnumberOfCustomers(this.firstAgentPerf),
              duration: this.duration,
            },
          ],
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/aiq-variables.scss";

.scrollable {
  overflow-y: auto;
}

.content-inner {
  & > div {
    max-width: 1100px;
    margin: 0 auto;
  }
  .header {
    margin-bottom: 40px;

    .date_selector {
      position: relative;
      width: 280px;
    }
  }
  .categories-row {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .content-inner {
    .header {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      .date_selector {
        visibility: hidden;
      }
    }
  }
}
</style>
