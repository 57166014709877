<template lang="pug"> 
  .container
    .cards-container
      segment-card(v-for="(segmentCard, idx) in displayedSegmentCards"
                  :key="segmentCard.key"
                  :idx="idx"
                  :is-removable="shouldShowRemove"
                  :card-data="segmentCard"
                  :all-segments="allCustomerSegments"
                  @change-segment="updateSegmentCard"
                  @remove-segment-card="removeSegmentCard")
    aiq-button.add-btn(v-if="shouldShowAdd" link type="primary" @click="addSegmentCard")
      fa-icon(:icon="['fas', 'circle-plus']")
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import max from 'lodash/max';
import { median } from '@/libs';
import SegmentCard from './children/SegmentCard.vue';
import PulsePanel from '../../../common/panel.vue';

const DEFAULT_DISPLAYED_SEGMENT_CARDS = ['anonymous', 'authenticated'];
const MAX_DISPLAYED_SEGMENT_CARDS_COUNT = 3;
const CARDS_TO_METRICS_MAP = [
  { label: 'Total Conversations' },
  {
    label: 'Agent',
    metric: 'conversations_served_by_agents_per_segment',
    conversationFilter: 'agent_served',
  },
  {
    label: 'AI Service',
    metric: 'conversations_served_by_ai_per_segment',
    conversationFilter: 'ai_served',
  },
  {
    label: 'Queued',
    metric: 'queue_wait_time_per_segment',
    deepPath: 'count_for_segment',
    conversationFilter: 'queued',
    tooltip: {
      metric: 'queue_wait_time_per_segment',
      content: [
        { label: 'Median Wait Time', deepPath: 'median_wait_for_segment', calculateForAll: median },
        { label: 'Maximum Wait Time', deepPath: 'max_wait_for_segment', calculateForAll: max },
      ],
    },
  },
  {
    label: 'Unresponded',
    metric: 'unresponded_wait_time_per_segment',
    deepPath: 'count_for_segment',
    conversationFilter: 'unresponded',
    tooltip: {
      metric: 'unresponded_wait_time_per_segment',
      content: [
        { label: 'Median Wait Time', deepPath: 'median_wait_for_segment', calculateForAll: median },
        { label: 'Maximum Wait Time', deepPath: 'max_wait_for_segment', calculateForAll: max },
      ],
    },
  },
];
const ALL_SEGMENTS_NAME = 'All';
const SEGMENT_SELECTIONS_CACHE_KEY = 'pulse/now/segments';


export default {
  extends: PulsePanel,
  name: 'PulseSegments',
  components: {
    SegmentCard,
  },
  props: {
    action: {
      type: String,
      default: 'getInstantMetrics',
    },
    storeKey: {
      type: String,
      default: 'instant',
    },
  },
  data() {
    return {
      displayedSegmentCards: [],
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch('configs/getCustomerSegments');
    } catch (err) {
      this.$aiq.notify.error(`Failed: ${err.message}`);
    }
  },
  computed: {
    ...mapGetters({
      customerSegments: 'configs/segments',
    }),
    allCustomerSegments() {
      if (this.customerSegments.length) {
        return [{ id: -1, name: ALL_SEGMENTS_NAME }, ...this.customerSegments];
      }
      return [];
    },
    shouldShowRemove() {
      return this.displayedSegmentCards.length > 1;
    },
    shouldShowAdd() {
      return this.displayedSegmentCards.length < MAX_DISPLAYED_SEGMENT_CARDS_COUNT;
    },
  },
  methods: {
    addSegmentCard() {
      if (this.shouldShowAdd) {
        this.displayedSegmentCards.push({ segmentName: '' });
      }
    },
    removeSegmentCard(idx) {
      this.displayedSegmentCards.splice(idx, 1);
    },
    updateSegmentCard(idx, newSegment) {
      this.displayedSegmentCards[idx] = {
        key: `${idx}-${newSegment}`,
        segmentName: newSegment,
        segmentMetrics: this.getMetricsBySegment(newSegment),
      };
    },
    getMetricsBySegment(segmentName) {
      const metricsBySegment = CARDS_TO_METRICS_MAP.map(
        ({ label, metric, deepPath, conversationFilter, tooltip }) => {
          let metricData = this.getTabularMetricData(metric);
          if (deepPath) {
            metricData = get(metricData, deepPath, {});
          }

          const tooltipData = this.getTabularMetricData(get(tooltip, 'metric'));

          let value = 0;
          const tooltipContent = [];

          if (segmentName === ALL_SEGMENTS_NAME) {
            for (const [dataKey, dataValue] of Object.entries(metricData)) {
              if (!['metric_category', 'new_conversations'].includes(dataKey)) {
                value += dataValue;
              }
            }

            if (!isEmpty(tooltipData)) {
              tooltip.content.forEach(contentItem => {
                const tooltipItemObj = get(tooltipData, contentItem.deepPath, {});
                const tooltipFormatter = this.formatterBasedOnCategory(tooltipItemObj);
                const tooltipDataValues = Object.entries(tooltipItemObj)
                  .filter(([dataKey]) => !['metric_category', 'new_conversations'].includes(dataKey))
                  .map(([_, dataValue]) => dataValue);
                if (tooltipDataValues) {
                  const aggregateValues = contentItem.calculateForAll(tooltipDataValues);
                  tooltipContent.push({
                    label: contentItem.label,
                    value: tooltipFormatter(aggregateValues),
                  });
                }
              });
            }
          } else {
            value = metricData[segmentName] || 0;

            if (!isEmpty(tooltipData)) {
              tooltip.content.forEach(contentItem => {
                const tooltipItemObj = get(tooltipData, contentItem.deepPath, {});
                const tooltipValue = tooltipItemObj[segmentName];
                const tooltipFormatter = this.formatterBasedOnCategory(tooltipItemObj);

                if (tooltipValue) {
                  tooltipContent.push({
                    label: contentItem.label,
                    value: tooltipFormatter(tooltipValue),
                  });
                }
              });
            }
          }

          return {
            label,
            value,
            ...(conversationFilter && {
              goToConversationParams: {
                active: true,
                customer_segments: segmentName === ALL_SEGMENTS_NAME ? [] : [segmentName],
                [conversationFilter]: true,
              },
            }),
            tooltipContent,
          };
        },
      );

      // Calculating the total conversations from the sume of various metrics
      metricsBySegment[0].value = // total conversations per segment
        metricsBySegment[1].value + // conversations served by agents per segment
        metricsBySegment[2].value + // conversations served by ai per segment
        metricsBySegment[3].value; // conversations queued per segment

      return metricsBySegment;
    },
  },
  watch: {
    'metrics.lastFetchedAt': function watchMetricsLastFetchedAt() {
      const cachedSegmentSelections = localStorage.getItem(SEGMENT_SELECTIONS_CACHE_KEY);

      let currentDisplayedSegments;
      if (this.displayedSegmentCards.length) {
        currentDisplayedSegments = this.displayedSegmentCards.map(card => card.segmentName);
      } else if (cachedSegmentSelections) {
        currentDisplayedSegments = cachedSegmentSelections.split(',');
      } else {
        currentDisplayedSegments = DEFAULT_DISPLAYED_SEGMENT_CARDS;
      }

      currentDisplayedSegments.forEach((segmentName, idx) => {
        this.updateSegmentCard(idx, segmentName);
      });
    },
    displayedSegmentCards: {
      handler(cards) {
        const segmentNames = cards.map(c => c.segmentName);
        localStorage.setItem(SEGMENT_SELECTIONS_CACHE_KEY, segmentNames);
      },
      deep: true,
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  min-height: 215px;

  .cards-container {
    flex-grow: 1;
    display: flex;
  }

  .add-btn {
    color: #0D8930;
  }
}
</style>
