import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

import isEqual from 'lodash/isEqual';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';

const { mapActions, mapState } = createNamespacedHelpers('configs');

const INFO = `Connect and integrate with Zendesk. <br><br>For more information and to
  get an access token, please refer to the Zendesk API docs <a target="_blank"
  href="https://developer.zendesk.com/rest_api/docs/core/introduction#api-token">here</a>.`;

export default {
  name: 'ZendeskTab',
  components: {
    ManagementPage,
  },
  data() {
    return {
      localForm: {
        username: null,
        accessToken: null,
      }
    };
  },
  computed: {
    ...mapState({
      savedForm: state => state.integrations.zendesk.form,
    }),
    pristine() {
      return isEqual(this.localForm, this.savedForm);
    },
    hasEmptyField() {
      return !this.localForm.username || !this.localForm.accessToken;
    },
  },
  created() {
    this.$_info = INFO;
  },
  watch: {
    savedForm: {
      handler(savedForm) {
        this.localForm = cloneDeep(savedForm);
      },
      deep: true
    }
  },
  methods: {
    ...mapActions([
      'loadZendesk',
      'updateZendesk',
    ]),
    load() {
      this.loadZendesk();
    },
    resetForm() {
      this.localForm = cloneDeep(this.savedForm);
    },
    saveForm() {
      return this.updateZendesk(this.localForm)
        .then(() => {
          this.$aiq.notify.success('Zendesk credentials saved.');
        }).catch(() => {
          this.$aiq.notify.error('Error saving Zendesk credentials.');
        });
    }
  },
};
