<template lang="pug">
management-page.scrollable(title="Timeline"
                :creatable="false"
                :searchable="false"
                :showHeader="false")
  template(v-slot:body)
    aiq-row(:gutter="16" justify="stretch")
        .chart-configuration
          .breakdown
            aiq-button-group.breakdown-options
              aiq-button(v-for="option in filteredBreakdownOptions"
                        :key="option.value"
                        size="small"
                        :class="{'selected': selectedBreakdownOption.value === option.value}"
                        @click="setBreakdown(option.value)"
                        ) {{ option.label}}
          .time-range-filter
            aiq-date-picker(v-model="dateRange"
                          type="daterange"
                          placeholder="Select date range",
                          popper-class="metrics-time-range__date-picker-popup"
                          :shortcuts="datePickerOptions.shortcuts"
                          :disabled-date="datePickerOptions.disabledDate"
                          :teleported="false"
                          format="YYYY/MM/DD"
                          :clearable="false")
    .chart-grid
        div(v-if="!(conversationsTimeline.conversations && conversationsTimeline.conversations.length)") No conversations in selected range
        div(v-else)
          aiq-row(:gutter="16"
                v-for="timeline in conversationsTimeline.conversations"
                :key="timeline.conversationId")
            aiq-col(:span="24")
                overview-chart(
                        :keepChartOnly="true"
                        :chartConfig="conversationsTimelineConfig"
                        :preLoadedchartData="{title: timeline.conversationId, datum: timeline.actorTimeline}"
                        :yScaleMin="timeline.conversationStart"
                        :yScaleMax="timeline.conversationEnd"
                        :y2ScaleMax="timeline.conversationStart + conversationsTimeline.maxLength"
                        visualization='aiq-timeline-chart')
</template>

<script>
import { get } from 'lodash';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import OverviewChart from '@/components/Chart.vue';
import conversationsTimelineConfig from '../../configs/conversationsTimeline';
import { datePickerDefaultOptions, defaultDateRange, breakdownOptions, filterBreakdownOptions } from '../../configs/dateRange';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

export default {
  components: {
    ManagementPage,
    OverviewChart,
  },
  data() {
    return {
      conversationsTimelineConfig,
      conversationsTimeline: {},
      dateRange: cloneDeep(defaultDateRange()),
      selectedBreakdownOption: breakdownOptions.find(
        ({ isDefault }) => isDefault,
      ),
    };
  },
  mounted() {
    this.loadConversationsTimeline();
  },
  computed: {
    datePickerOptions() {
      return datePickerDefaultOptions();
    },
    // TODO: Create a mixin that has this computed value,
    // along with other common properties and functions in metrics
    filteredBreakdownOptions() {
      return filterBreakdownOptions(this.dateRange);
    },
  },
  watch: {
    dateRange(value, oldValue = []) {
      const [nStart, nEnd] = value;
      if (nStart && nEnd && nStart === nEnd) {
        return;
      }
      const [start, end] = oldValue;
      if (!(start && end)) {
        return;
      }
      this.loadConversationsTimeline();
    },
    selectedBreakdownOption(value, oldValue) {
      if (!oldValue || value === oldValue) {
        return;
      }
      this.loadConversationsTimeline();
    },
    filteredBreakdownOptions(newOptions) {
      if (!newOptions.find(o => o.value === this.selectedBreakdownOption.value)) {
        this.selectedBreakdownOption = newOptions.find(o => o.isDefault);
      }
    },
  },
  methods: {
    setBreakdown(value) {
      this.selectedBreakdownOption = this.filteredBreakdownOptions.find(
        option => option.value === value,
      );
    },
    loadConversationsTimeline() {
      const periodStart = moment(this.dateRange[0]).unix();
      const periodEnd = moment(this.dateRange[1]).unix();
      if (!(periodStart && periodEnd)) {
        return;
      }
      const params = {
        period_start: periodStart,
        period_end: periodEnd,
        bucket: this.selectedBreakdownOption.value,
      };
      return this.$store
        .dispatch('metrics/getMetric', [conversationsTimelineConfig.metric, params])
        .then(data => {
          this.conversationsTimeline = this.extractAllTimelineData(get(data, 'dataset.0.data_points', []));
        })
        .catch(() => {
          this.$aiq.notify.error('Unable to fetch data.');
        });
    },
    convertToSeconds(timeStr) {
      return moment.duration(Number(timeStr), 'milliseconds').asSeconds();
    },
    // TODO: Most of the filtering messages needs to be done on the backend
    extractAllTimelineData(dataPoints) {
      const allTimelines = { conversations: [], maxLength: 0 };
      let maxLength = 0;
      for (const { actor_timeline, conversation_id } of dataPoints) {
        const formattedTimeline = [];
        let conversationStart = moment().unix();
        let conversationEnd = 0;

        for (const { actor_label, times } of actor_timeline) {
          const elementsCount = 3;
          const [actorId, actorType, messageType] = actor_label.split('_', elementsCount);
          if (
            messageType === 'text' &&
            this.conversationsTimelineConfig.lines.domain.includes(actorType) &&
            actorId !== this.conversationsTimelineConfig.agentBotId
          ) {
            const relevantTimes = [];
            for (const { starting_time, ending_time } of times) {
              if (ending_time && starting_time !== ending_time) {
                const startingTime = this.convertToSeconds(starting_time);
                const endingTime = this.convertToSeconds(ending_time);
                conversationStart = Math.min(conversationStart, startingTime);
                conversationEnd = Math.max(conversationEnd, endingTime);
                relevantTimes.push({
                  starting_time: startingTime,
                  ending_time: endingTime,
                });
              }
            }
            if (relevantTimes.length) {
              formattedTimeline.push({
                label: actor_label,
                name: actor_label,
                actorType,
                times: relevantTimes,
              });
            }
          }
        }
        if (formattedTimeline.length) {
          maxLength = Math.max(conversationEnd - conversationStart, maxLength);
          allTimelines.conversations.push({
            actorTimeline: formattedTimeline,
            conversationId: conversation_id,
            conversationStart,
            conversationEnd,
          });
        }
      }
      allTimelines.maxLength = maxLength;
      return allTimelines;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/aiq-mixins.scss";
@import "@/styles/aiq-variables.scss";
@import "../../../../styles/aiq-variables.scss";

.scrollable {
  @include scrollable(calc(100vh - 225px));
}

.el-card:not(:first-of-type) {
  margin-top: 24px;
}

.time-range-filter {
  position: relative;
}


.dropdown-bar {
    display: flex;
    .dropdown-filters {
        display: flex;
        align-items: flex-start;

        .dropdown-filter {
            margin-right: 12px;
            width: 22em;
            height: 30px;
            margin-bottom: 8px;
        }

        .dropdown-filter-type {
            margin-right: 12px;
            width: 18em;
            height: 30px;
            margin-bottom: 8px;
        }
        margin-bottom: 12px;
        margin-top: 24px;

    }
}
.chart-configuration {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-around;
  width: 100%;
  
  .breakdown {
    display: flex;
    align-items: center;
  }
}
</style>
