<template lang="pug">
.device-info
  h3.customer-info-basic_title {{ $t('conversation_tab.side_panel.customer_info_accordion.section.feedback_info.title') }}
    .customer-info-basic_field(v-for="info in details")
      label {{info.name}}:
      .noneditable {{ info.value }}

</template>

<script>
import get from 'lodash/get';
import moment from 'moment';

export default {
  props: {
    info: {
      type: Object,
      default: { 
        customer_id: 0,
        last_feedback_sent_at: '',
        last_feedback_completed_at: '',
        last_feedback_score: 0,
        last_feedback_comment: ''
      },
    },
  },
  methods: {
    formatTime(timeStr) {
      if (!timeStr) {
        return '';
      }
      return moment(timeStr).format('MMM DD, YYYY hh:mm A');
    },
  },
  computed: {
    details() {
      return [
        {
          name: this.$t('conversation_tab.side_panel.customer_info_accordion.section.feedback_info.last_sent'),
          value: this.formatTime(get(this.info, 'last_feedback_sent_at', '')),
        },
        {
          name: this.$t('conversation_tab.side_panel.customer_info_accordion.section.feedback_info.last_completed'),
          value: this.formatTime(get(this.info, 'last_feedback_completed_at', '')),
        },
        {
          name: this.$t('conversation_tab.side_panel.customer_info_accordion.section.feedback_info.last_score'),
          value: get(this.info, 'last_feedback_score', ''),
        },
        {
          name: this.$t('conversation_tab.side_panel.customer_info_accordion.section.feedback_info.last_comment'),
          value: get(this.info, 'last_feedback_comment', 'NA'),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-info-basic_title {
  color: #48576A;
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
}

.customer-info-basic_field {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 8px;

  .noneditable {
    font-size: 11px;
    font-weight: 300;
    width: 200px;
    padding-right: 10px;
  }

  > label {
    width: 97px;
    color: #324057;
    font-size: 11px;
    line-height: 13px;
    font-weight: 300;
  }
}
</style>
